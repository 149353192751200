import React, { useState, useEffect } from 'react';
import { IonTitle, IonToolbar, IonHeader, IonContent, IonBackButton, IonPage, IonButtons, IonRouterLink } from '@ionic/react';
import { useHistory, useParams, RouteComponentProps } from 'react-router-dom';
import { CONN_KEY } from '../Conn';
import axios from "axios";
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

interface ResetProps extends RouteComponentProps<{ id: string }> { }

const RezervM: React.FC<ResetProps> = ({ match }) => {
    const [currentHour, setCurrentHour] = useState<number>(new Date().getHours());
    const [RezervStart, setRezervStart] = useState<number>(0);
    const [RezervEnd, setRezervEnd] = useState<number>(0);
    const [aktiveEllips, setAktiveEllips] = useState<boolean>(false);
    const [aktiveExtra, setAktiveExtra] = useState<boolean>(false);
    const [aktiveAll, setAktiveAll] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState<any>(null);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        const fetchReservationSettings = async () => {
            try {
                const responseSettings = await axios.get(`${CONN_KEY}Settings.php`);
                const dataSettings = responseSettings.data;

                if (isMounted && dataSettings && dataSettings.RezervStart) {
                    setRezervStart(dataSettings.RezervStart);
                    setRezervEnd(dataSettings.RezervEnd);
                } else {
                    console.log('Invalid response format: Missing RezervStart');
                }
            } catch (error) {
                if (isMounted) {
                    console.log('Error fetching reservation settings:', error);
                }
            }
        };

        const fetchAktiveStatus = async () => {
            try {
                const responseAktive = await axios.get(`${CONN_KEY}aktive.php`);
                const dataAktive = responseAktive.data;

                if (isMounted && dataAktive) {
                    setAktiveEllips(dataAktive.Aktive === 'okEllips' || dataAktive.Aktive === 'ok');
                    setAktiveExtra(dataAktive.Aktive === 'okExtra' || dataAktive.Aktive === 'ok');
                    setAktiveAll(dataAktive.Aktive === 'ok');
                }
            } catch (error) {
                if (isMounted) {
                    console.log('Error fetching aktive status:', error);
                }
            }
        };

        fetchReservationSettings();
        fetchAktiveStatus();

        const intervalId = setInterval(() => {
            if (isMounted) {
                fetchAktiveStatus();
            }
        }, 3 * 60 * 1000); // Update every 3 minutes

        return () => {
            isMounted = false; // Set flag to false when component unmounts
            clearInterval(intervalId); // Clear the interval
        };
    }, []);

    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        const fetchUserData = () => {
            axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
                .then(res => {
                    if (isMounted) {
                        setUser(res.data);
                    }
                })
                .catch(error => {
                    if (isMounted) {
                        console.error('Error fetching user data:', error);
                    }
                });
        };

        fetchUserData(); // Fetch data initially

        const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds

        return () => {
            isMounted = false; // Set flag to false when component unmounts
            clearInterval(intervalId); // Cleanup the interval
        };
    }, [id]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <div className='tabbarmains'>
                        <IonBackButton defaultHref={user ? `/dashboard/${user.id}` : ""} />
                    </div>
                    <IonTitle style={{ zIndex: '-1' }}>Rezerv</IonTitle>
                    <IonButtons slot="primary">
                        {currentHour >= RezervStart && currentHour < RezervEnd && (aktiveEllips || aktiveExtra || aktiveAll) && (
                            <div className="circle"></div>
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center">
                <div className='sanjmvd container'>
                    <IonRouterLink
                        routerLink={`/dashboard/Extra/${id}`}
                        className={!aktiveExtra && !aktiveAll ? 'disabled' : ''}
                        onClick={!aktiveExtra && !aktiveAll ? (e) => e.preventDefault() : undefined}
                    >
                        <div className='sadfbv' style={{ background: 'var(--mapsfe1)' }}>
                            <div className='gd3'>
                                <img src='assets/images/logo.png' alt='Extra' />
                            </div>
                        </div>
                    </IonRouterLink>
                    <IonRouterLink
                        routerLink={`/dashboard/Ellips/${id}`}
                        className={!aktiveEllips && !aktiveAll ? 'disabled' : ''}
                        onClick={!aktiveEllips && !aktiveAll ? (e) => e.preventDefault() : undefined}
                    >
                        <div className='sadfbv' style={{ background: 'var(--mapsfe2)' }}>
                            <div className='gd3'>
                                <img src='assets/images/ellipslogo.png' alt='Ellips' />
                            </div>
                        </div>
                    </IonRouterLink>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default RezervM;
