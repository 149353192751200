import {
    IonButton, IonContent, IonHeader, IonItem, IonLabel, IonLoading, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonTextarea, IonBackButton,
} from '@ionic/react';
import { Space } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

// Custom type guard to check if the error is an Axios error
const isAxiosError = (error: any): error is AxiosError => {
    return error.isAxiosError;
};

interface StatComProps extends RouteComponentProps<{ id: string }> { }

const GoogleTranslate: React.FC<StatComProps> = ({ match }) => {
    const userId = match.params.id;
    const [inputText, setInputText] = useState<string>('');
    const [translatedText, setTranslatedText] = useState<string>('');
    const [sourceLanguage, setSourceLanguage] = useState<string>('az'); // Default to Azerbaijani
    const [targetLanguage, setTargetLanguage] = useState<string>('ar'); // Default to Arabic
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        console.log(`Component mounted with user ID: ${userId}`);
    }, [userId]);

    const translateText = async () => {
        const apiKey = 'AIzaSyCmuLv2Ivhrasi-XwPBsdJMgbwVAmKGuC4'; // Replace with your API key
        const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

        const data = {
            q: inputText,
            source: sourceLanguage,
            target: targetLanguage,
        };

        try {
            setLoading(true);
            const response = await axios.post(url, data);

            if (response.status === 200) {
                setTranslatedText(response.data.data.translations[0].translatedText);
            } else {
                setError(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response) {
                    setError(`Error: ${error.response.status}`);
                } else if (error.request) {
                    setError('No response received from the server.');
                } else {
                    setError('An error occurred while setting up the request.');
                }
            } else {
                setError('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    const resetText = () => {
        setInputText('');
        setTranslatedText('');
    };

    const swapLanguages = () => {
        const temp = sourceLanguage;
        setSourceLanguage(targetLanguage);
        setTargetLanguage(temp);
    };

    const getLanguageName = (langCode: string): string => {
        switch (langCode) {
            case 'az':
                return 'Azerbaijani';
            case 'ar':
                return 'Arabic';
            case 'fa':
                return 'Persian (Farsi)';
            case 'en':
                return 'English';
            case 'ru':
                return 'Russian';
            case 'tr':
                return 'Turkish';
            default:
                return 'Unknown';
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <div className='tabbarmains'>
                        <IonBackButton defaultHref={`/dashboard/${userId}`} />
                    </div>
                    <IonTitle>Translate</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <div className='trs-cont'>
                <Space align="center" style={{ display:'flex',justifyContent:'space-around' }}>
                        <IonItem>
                            <IonSelect value={sourceLanguage} onIonChange={(e) => setSourceLanguage(e.detail.value)}>
                                <IonSelectOption value="az">Azerbaijani</IonSelectOption>
                                <IonSelectOption value="ar">Arabic</IonSelectOption>
                                <IonSelectOption value="fa">Persian (Farsi)</IonSelectOption>
                                <IonSelectOption value="en">English</IonSelectOption>
                                <IonSelectOption value="ru">Russian</IonSelectOption>
                                <IonSelectOption value="tr">Turkish</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <SwapOutlined onClick={swapLanguages} />
                        <IonItem>
                            <IonSelect value={targetLanguage} onIonChange={(e) => setTargetLanguage(e.detail.value)}>
                                <IonSelectOption value="az">Azerbaijani</IonSelectOption>
                                <IonSelectOption value="ar">Arabic</IonSelectOption>
                                <IonSelectOption value="fa">Persian (Farsi)</IonSelectOption>
                                <IonSelectOption value="en">English</IonSelectOption>
                                <IonSelectOption value="ru">Russian</IonSelectOption>
                                <IonSelectOption value="tr">Turkish</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </Space>
                    {loading && <IonLoading isOpen={loading} message={'Translating...'} />}
                    {error && (
                        <div>
                            <pre>{error}</pre>
                        </div>
                    )}
                    {translatedText && (
                        <div className='trss rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500'>{translatedText}</div>
                    )}
                    <div className='h-auto' style={{ height: '100%' }}>
                        <IonLabel position="stacked">Text in {getLanguageName(sourceLanguage)}</IonLabel>
                        <IonItem>
                            <IonTextarea
                                value={inputText}
                                className='ion-input'
                                placeholder={`Enter text in ${getLanguageName(sourceLanguage)}`}
                                onIonChange={(e) => setInputText(e.detail.value!)}
                            />
                        </IonItem>
                    </div>
                    <div className="button-group">
                        <IonButton className='w-full' color="danger" fill="clear" onClick={resetText}>Reset</IonButton>
                        <IonButton className='w-full' style={{ width: '100%' }} fill="outline" onClick={translateText}>Translate</IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage >
    );
};

export default GoogleTranslate;
