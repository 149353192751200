import React, { useState, useEffect } from "react";
import Stories from "stories-react";
import { useParams, useHistory } from 'react-router-dom';
import "stories-react/dist/index.css";
import { IonButton, IonContent, IonPage, IonRouterLink, IonText, IonIcon } from "@ionic/react";
import axios from "axios";
import { CONN_KEY } from "../Conn";
import { ref, get, runTransaction } from "firebase/database";
import { database } from "../firebaseConfig";
import { refreshOutline } from 'ionicons/icons'; // Import refresh icon

// Define the type for a story object
interface Story {
    type: "image" | "video" | "component";
    url?: string;
    duration?: number;
    component?: React.FC;
}

function ImagesStories({ stories, onEnd, currentIndex, setStoryIndex }: { stories: Story[], onEnd: () => void, currentIndex: number, setStoryIndex: (index: number) => void }) {
    const [mediaError, setMediaError] = useState<boolean>(false);

    const handleTap = () => {
        if (currentIndex === stories.length - 1) {
            onEnd();
        } else {
            setStoryIndex(currentIndex + 1);
        }
    };

    const handleMediaError = (e: any) => {
        if (e && e.type === 'error') {
            setMediaError(true);
        }
    };

    return (
        <>
            {mediaError ? (
                <div className="no-data-container">
                    <IonText>Unable to load media. It might be blocked by the browser or server settings.</IonText>
                </div>
            ) : (
                <Stories
                    stories={stories}
                    currentIndex={currentIndex}
                    onStoryChange={setStoryIndex}
                    onAllStoriesEnd={onEnd}
                    render={({ story, index }: { story: Story, index: number }) => {
                        if (story.type === "video") {
                            return (
                                <video
                                    src={story.url}
                                    muted
                                    autoPlay
                                    playsInline
                                    loop={false}
                                    onError={handleMediaError}
                                    onEnded={() => {
                                        if (index === stories.length - 1) {
                                            onEnd();
                                        }
                                    }}
                                    onClick={handleTap}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            );
                        } else if (story.type === "image") {
                            return (
                                <img
                                    src={story.url}
                                    alt="story"
                                    style={{ width: '100%', height: 'auto' }}
                                    onError={handleMediaError}
                                    onClick={handleTap}
                                />
                            );
                        } else if (story.component) {
                            const Component = story.component;
                            return <Component />;
                        }
                        return null;
                    }}
                />
            )}
        </>
    );
}

const Storie: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [stories, setStories] = useState<Story[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [storyIndex, setStoryIndex] = useState<number>(0);
    const [isStoryEnded, setIsStoryEnded] = useState<boolean>(false);

    const logStoryView = (storyId: string) => {
        const storyRef = ref(database, `storyViews/${storyId}/viewCount`);

        runTransaction(storyRef, (currentValue) => {
            return (currentValue || 0) + 1;
        })
            .then(() => {
                console.log(`View count incremented for story ID: ${storyId}`);
            })
            .catch((error) => {
                console.error('Error incrementing view count in Firebase:', error);
            });
    };

    const handleStoriesEnd = () => {
        setIsStoryEnded(true); // Set this to true when stories end
    };

    useEffect(() => {
        setStoryIndex(0);
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${CONN_KEY}Storie.php`);
            if (response.data && Array.isArray(response.data)) {
                if (response.data.length === 0) {
                    // If the response is an empty array, redirect to /dashboard/${id}
                    history.push(`/dashboard/${id}`);
                } else {
                    const transformedStories = response.data.map((item: any) => {
                        const isVideo = item.name.endsWith(".mp4") || item.name.endsWith(".webm");
                        const story: Story = {
                            type: isVideo ? "video" : "image",
                            url: `https://appmobile.svurguns.cyou/Data/api/app123/Storie/${item.name}`,
                            duration: isVideo ? undefined : 5000,
                        };

                        logStoryView(item.id);
                        return story;
                    });
                    setStories(transformedStories);
                    setIsLoading(false);
                }
            } else {
                throw new Error('Invalid data format');
            }
        } catch (error) {
            console.error('Error fetching story data:', error);
            setError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshPage = () => {
        window.location.reload(); // Refresh the page
    };

    return (
        <IonPage className="Storie">
            <IonContent>
                {isLoading ? (
                    <div className="loading-container">
                        <IonText>Loading...</IonText>
                    </div>
                ) : error ? (
                    <div className="no-data-container">
                        <IonText>No data available</IonText>
                    </div>
                ) : (
                    <>
                        <ImagesStories
                            stories={stories}
                            currentIndex={storyIndex}
                            setStoryIndex={setStoryIndex}
                            onEnd={handleStoriesEnd}
                        />
                        {isStoryEnded && (
                            <div className="overlay-container">
                                <IonRouterLink routerLink={`/dashboard/${id}`}>
                                    <IonButton className="go-dashboard-button" fill="outline">
                                        Bağla
                                    </IonButton>
                                </IonRouterLink>
                                <IonButton className="refresh-button" fill="clear" onClick={refreshPage}>
                                    <IonIcon icon={refreshOutline} slot="start" />
                                    Yenilə
                                </IonButton>
                            </div>
                        )}
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default Storie;
