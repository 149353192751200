import React, { useState, useRef } from "react";

const EasySlideButton = ({
    onComplete,
    actionText = "Slide to Confirm",
    completedText = "Complete!",
    width = 100,
    height = 50,
    sliderColor = "#4caf50",
    backgroundColor = "#ddd",
    textColor = "#666",
    disabled = false,
}: {
    onComplete: () => void;
    actionText?: string;
    completedText?: string;
    width?: number;
    height?: number;
    sliderColor?: string;
    backgroundColor?: string;
    textColor?: string;
    disabled?: boolean;
}) => {
    const [sliderProgress, setSliderProgress] = useState(10); // Track progress
    const sliderRef = useRef<HTMLDivElement>(null);
    const [isSliding, setIsSliding] = useState(false);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (disabled) return;

        const slider = sliderRef.current;
        if (!slider) return;

        const rect = slider.getBoundingClientRect();
        setIsSliding(true);

        const handleMouseMove = (event: MouseEvent) => {
            if (!event.clientX) return;

            const offsetX = Math.min(Math.max(0, event.clientX - rect.left), rect.width);
            const progress = (offsetX / rect.width) * 100;
            setSliderProgress(progress);

            if (progress >= 100) {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
                setTimeout(() => setSliderProgress(10), 500); // Reset after completing
                onComplete();
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            if (sliderProgress < 100) setSliderProgress(10); // Reset if not complete
            setIsSliding(false);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        if (disabled) return;

        const slider = sliderRef.current;
        if (!slider) return;

        const rect = slider.getBoundingClientRect();
        setIsSliding(true);

        const handleTouchMove = (event: TouchEvent) => {
            if (!event.touches) return;

            const offsetX = Math.min(
                Math.max(0, event.touches[0].clientX - rect.left),
                rect.width
            );
            const progress = (offsetX / rect.width) * 100;
            setSliderProgress(progress);

            if (progress >= 100) {
                document.removeEventListener("touchmove", handleTouchMove);
                document.removeEventListener("touchend", handleTouchEnd);
                setTimeout(() => setSliderProgress(10), 500); // Reset after completing
                onComplete();
            }
        };

        const handleTouchEnd = () => {
            document.removeEventListener("touchmove", handleTouchMove);
            document.removeEventListener("touchend", handleTouchEnd);
            if (sliderProgress < 100) setSliderProgress(10); // Reset if not complete
            setIsSliding(false);
        };

        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", handleTouchEnd);
    };

    return (
        <div
            ref={sliderRef}
            style={{
                position: "relative",
                width: `${width}%`,
                height: `${height}px`,
                backgroundColor: disabled ? "#e0e0e0" : backgroundColor,
                borderRadius: `20px`,
                overflow: "hidden",
                userSelect: "none",
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.6 : 1,
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onMouseDown={disabled ? undefined : handleMouseDown}
            onTouchStart={disabled ? undefined : handleTouchStart}
        >
            {/* Slider Progress */}
            <div
                style={{
                    width: `${sliderProgress}%`,
                    height: "100%",
                    borderRadius: "20px",
                    backgroundColor: sliderColor,
                    transition: sliderProgress === 10 ? "width 0.3s ease" : "none",
                }}
            />

            {/* Slider Text */}
            <span
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    color: textColor,
                    fontSize: "16px",
                    fontWeight: "500",
                    pointerEvents: "none",
                }}
            >
                {sliderProgress >= 100 ? completedText : actionText}
            </span>
        </div>
    );
};

export default EasySlideButton;
