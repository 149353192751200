import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CONN_KEY } from '../Conn';
import axios from 'axios';
import { IonContent, IonPage, IonBackButton, IonToolbar, IonTitle, IonButton, IonHeader } from '@ionic/react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import CheckComp1 from '../components/check';
import StatRej from '../components/statism';
import StatCom from '../components/statComplate';
// import { LocalNotifications } from '@capacitor/local-notifications';

interface ResetProps extends RouteComponentProps<{ id: string }> { }

const CheckComp: React.FC<ResetProps> = ({ match }) => {
    const [user, setUser] = useState<any>(null);
    const { id } = useParams<{ id: string }>(); // Retrieve selectedFlourId from URL path
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };

    useEffect(() => {
        // Check if the code is running in a browser environment
        if (typeof window !== 'undefined') {
            const gaULCookie = getCookie('_gaUL');
            if (!gaULCookie || gaULCookie !== id) {
                // Don't perform redirection if _gaUL cookie doesn't exist or doesn't match id
                return;
            }
        }

        setIsLoading(false);

        const fetchUserData = () => {
            axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
                .then(res => {
                    setUser(res.data);
                    // If you have a calculateCountdown function, call it here
                    // calculateCountdown(res.data.endtime);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        fetchUserData(); // Fetch data initially

        const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds

        return () => {
            clearInterval(intervalId); // Cleanup the interval when component unmounts
        };
    }, [history, id]);

    // const sendLocalNotification = async () => {
    //     try {
    //         await LocalNotifications.schedule({
    //             notifications: [
    //                 {
    //                     title: "Test Notification",
    //                     body: "This is a test local notification.",
    //                     id: 1,
    //                     schedule: { at: new Date(Date.now() + 1000 * 5) }, // Schedule after 5 seconds
    //                     sound: undefined, // Use undefined instead of null
    //                     attachments: [
    //                         { id: 'icon', url: 'public/assets/icon/extraLogo.png', options: {} }
    //                     ], // Path to the icon file
    //                     actionTypeId: "",
    //                     extra: undefined // Use undefined instead of null Only available for iOS.
    //                 }
    //             ]
    //         });
    //         setError(null); // Clear any previous error
    //     } catch (err) {
    //         setError('Failed to schedule the notification.'); // Set error message
    //     }
    // };

    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonHeader>
                <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <div className='tabbarmains'>
                        <IonBackButton defaultHref={user ? `/dashboard/${user.id}` : ""} />
                    </div>
                    <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Əməliyyatlar</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} className='ion-padding'>
                <div className='container'>
                    <div className='sffgdj'>
                        <StatRej id={id} />
                        <StatCom id={id} />
                    </div>
                    <CheckComp1 />
                </div>
                {/* <IonButton onClick={sendLocalNotification}>Send Test Notification</IonButton> */}
                {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
            </IonContent>
        </IonPage>
    );
};

export default CheckComp;
