import React from 'react';
import { IonImg } from '@ionic/react';

const NotFound: React.FC = () => (
    <div className="">
        <IonImg src='assets/images/notfound2.svg' alt="Not Found" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        {/* <h2>Rezerv tapılmadı</h2> */}
        <p>Tapılmadı</p>
    </div>
);

export default NotFound;
