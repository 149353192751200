import { IonBackButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { Card, Spin, Typography, Button, Input, message, Tag, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import { CONN_KEY } from "../Conn";
import EasySlideButton from "../pages/MasassExtra/SlideButton"; // Ensure correct path
import { RouteComponentProps } from "react-router-dom";
import { useRef } from "react";

const { Title, Text } = Typography;

interface CaptchaComponentProps
    extends RouteComponentProps<{
        id: string;
    }> { }

const CaptchaComponent: React.FC<CaptchaComponentProps> = ({ match }) => {
    const [selectedRestaurant, setSelectedRestaurant] = useState<string | null>(null);
    const [selectedDoor, setSelectedDoor] = useState<string | null>(null);
    const [captchaValid, setCaptchaValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isBirthdayConfirmed, setIsBirthdayConfirmed] = useState(false);
    const [isKeyConfirmed, setIsKeyConfirmed] = useState(false);
    const [confirmationHash, setConfirmationHash] = useState("");
    const [restaurants, setRestaurants] = useState<{ id: number; name: string; address: string; lat: number; lng: number }[]>([]);
    const [user, setUser] = useState({
        id: null,
        name: "",
        queueNumber: null,
        Cardid: null,
        timestamp: null,
        door: null,
    });

    useEffect(() => {
        initialize();
    }, []);

    const captchaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (step === 3) {
            setTimeout(() => {
                loadCaptchaEnginge(6);
            }, 500); // Delay to ensure component has mounted
        }
    }, [step]);    
    
    const initialize = async () => {
        const storedCardid = localStorage.getItem("Cardid");

        if (storedCardid) {
            await fetchQueueNumber(storedCardid);
        } else {
            fetchUserData();
        }
    };

    const fetchUserData = async () => {
        try {
            setIsLoading(true);
            const storedUserId = localStorage.getItem("userId");
            if (!storedUserId) {
                message.error("User ID not found. Redirecting to login...");
                setTimeout(() => {
                    window.location.href = "/login";
                }, 3000);
                return;
            }

            const response = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${storedUserId}`);
            if (response.data) {
                const { Cardid, queueNumber } = response.data;

                if (Cardid) {
                    localStorage.setItem("Cardid", Cardid);
                }

                setUser((prev) => ({
                    ...prev,
                    id: response.data.id,
                    name: response.data.name,
                    queueNumber: queueNumber || null,
                    Cardid: Cardid || null,
                }));

                if (queueNumber) {
                    setStep(0);
                } else {
                    setStep(1);
                }
            } else {
                message.warning("No user data found.");
                setStep(1);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            message.error("Failed to fetch user data.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Function to fetch queue number every 5 seconds
        const interval = setInterval(() => {
            const storedCardid = localStorage.getItem("Cardid");
            if (storedCardid) {
                fetchQueueNumber(storedCardid);
            }
        }, 5000); // 5000ms = 5 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    const fetchQueueNumber = async (Cardid: string) => {
        try {
            // setIsLoading(true);
            const response = await axios.post(
                `${CONN_KEY}getQueueNumber.php`,
                JSON.stringify({ Cardid }),
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_AUTH_KEY}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.queueNumber) {
                setUser((prev) => ({
                    ...prev,
                    queueNumber: response.data.queueNumber,
                    Status: response.data.Status || null,
                    timestamp: response.data.timestamp || null,
                    door: response.data.door || null,
                    ComingTime: response.data.ComingTime || null,
                }));
                setStep(0);
            } else {
                setStep(1);
            }
        } catch (error) {
            console.error("Error fetching queue number:", error);
            // message.error("Failed to fetch queue number.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRestaurantSelection = (restaurant: string) => {
        setSelectedRestaurant(restaurant);
        if (restaurant === "Extra") {
            setStep(3);
        } else {
            setStep(3);
        }
    };

    const handleCaptchaVerify = async () => {
        await handleSlideCompleteKey(); // Ensure key confirmation first

        const userCaptchaInput = (document.getElementById("user_captcha_input") as HTMLInputElement)?.value || "";

        if (validateCaptcha(userCaptchaInput)) {
            setCaptchaValid(true);
            message.success("Captcha verified successfully!");

            // Move directly to table selection
            setTimeout(() => {
                handleTableSelection(); // Call handleTableSelection immediately
            }, 500);
        } else {
            message.error("Captcha verification failed. Please try again.");
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userId = match.params.id; // Get the `id` from route params
                if (!userId) {
                    console.error("No ID provided. Cannot fetch user data.");
                    return;
                }
    
                const response = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
                const data = response.data;
    
                // Handle data as needed
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
    
        fetchUserData();
    }, [match.params.id]); // Depend on `match.params.id` to update when the route changes
    
    const handleTableSelection = async () => {
        try {
            const storedCardid = localStorage.getItem("Cardid");

            if (!storedCardid) {
                setErrorMessage("❌ Kart ID tapılmadı. Zəhmət olmasa yenidən daxil olun.");
                return;
            }

            setIsLoading(true);
            setErrorMessage(null); // Clear previous error

            const response = await axios.post(
                `${CONN_KEY}getTurnNumber.php`,
                JSON.stringify({ Cardid: storedCardid, Door: selectedRestaurant }),
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_AUTH_KEY}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("✅ TurnNumber Response:", response.data);

            if (!response.data.turnNumber) {
                throw new Error(response.data.message || "Turn nömrəsi alınmadı.");
            }

            const { turnNumber, timestamp, Status, door } = response.data;

            setUser((prev) => ({
                ...prev,
                queueNumber: turnNumber,
                timestamp,
                Status,
                door: door || selectedRestaurant,
            }));

            setStep(1);
        } catch (error: any) {
            console.error("❌ Failed to get turn number:", error);

            let errorMessage = "Turn nömrəsi alınmadı. Zəhmət olmasa yenidən yoxlayın.";

            // Just show whatever message comes from the API response
            if (error.response?.data?.message) {
                errorMessage = error.response.data.message;
            }

            setErrorMessage(errorMessage); // 🔴 SET ERROR MESSAGE IN STATE
            message.error(errorMessage);   // 🔴 SHOW MESSAGE POPUP
        } finally {
            setIsLoading(false);
        }
    };
    const storedUserId = localStorage.getItem("userId");

    useEffect(() => {
        fetchRestaurants();
    }, []);
    const generateRandomKey = (length = 16): string => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setConfirmationHash(result); // Save the key to the state
        setIsBirthdayConfirmed(false);
        return result;
    };
    const handleSlideCompleteKey = async () => {
        const randomKey = generateRandomKey();
        const userId = user.id || localStorage.getItem("userId"); // Try from state first, then localStorage
    
        if (!userId) {
            message.error("User ID not found. Please log in again.");
            return;
        }
    
        try {
            const response = await axios.post(`${CONN_KEY}confirmationKey.php`, {
                userid: userId,
                key: randomKey,
            });
    
            if (response.data.status === "success") {
                // message.success(response.data.message );
                setIsKeyConfirmed(true); // ✅ Key confirmed successfully
    
                // 👉 After successful key confirmation, move to Captcha step
                setStep(2); // Now user can verify Captcha
            } else {
                message.error(response.data.message || "Failed to generate random key.");
            }
        } catch (error) {
            console.error("Error during key generation:", error);
            message.error("Failed to generate random key.");
        }
    };
    
    const fetchRestaurants = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${CONN_KEY}WebReserv/getRestaurants.php`);
            if (response.data.success) {
                setRestaurants(response.data.restaurants);
            } else {
                message.warning("No available restaurants.");
            }
        } catch (error) {
            console.error("Error fetching restaurants:", error);
            message.error("Failed to fetch restaurants.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <div className="tabbarmains">
                        <IonBackButton defaultHref={`/dashboard/${storedUserId}`} />
                    </div>
                    <IonTitle>Reservation</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-padding ion-text-center">
                <Card style={{ margin: "20px auto", maxWidth: 400 }} headStyle={{ textAlign: "center" }}>
                    {isLoading && <Spin tip="Loading..." />}

                    {step === 0 && user.queueNumber && (
                        <div style={{ textAlign: "center", background: "#f0f0f0", padding: "20px" }}>
                            <Title level={4}>Sıra nömrəniz</Title>
                            <h1>{user.queueNumber}</h1>

                            <Text strong>Ərazi : </Text>
                            <Text>{user.door || "N/A"}</Text>
                            <br/>
                            <Text strong>Növbənizin vaxdı :  {user.ComingTime || "N/A"} </Text>

                            {/* Late Reservation Warning*/}
                            <div style={{ padding: "10px", borderRadius: "5px", marginTop: "10px" }}>
                                <Text strong style={{ color: "red", fontSize: "16px" }}>🚨 Diqqət!</Text>
                                <p style={{ color: "red", fontSize: "14px" }}>
                                    **Zəhmət olmasa, gecikməyin.** ⏳ yoxsa sıranız başqalarına keçə bilər.
                                </p>
                            </div> 
                        </div>
                    )}
                    {step === 1 && (
                        <>
                            <Title level={4}>Select a Restaurant</Title>
                            {/* 🔹 Slide to Confirm Before Opening Captcha */}
                            <EasySlideButton
                                onComplete={handleSlideCompleteKey} // ✅ Slide first to confirm key before Captcha
                                actionText="Başlamaq üçün sürüşdürün"
                                completedText="Confirmed! Proceeding to Captcha..."
                                height={50}
                                sliderColor="#FF5722"
                                backgroundColor="#f5f5f5"
                                textColor="#000"
                            />
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <Title level={4}>Select a Restaurant</Title>
                            {restaurants
                                .filter((restaurant) => restaurant.status === 1) // Show only if status = 1
                                .map((restaurant) => (
                                    <Button
                                        key={restaurant.id}
                                        block
                                        size="large"
                                        onClick={() => handleRestaurantSelection(restaurant.name)}
                                    >
                                        {restaurant.name}
                                    </Button>
                                ))
                            }
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <LoadCanvasTemplate />
                            <Input id="user_captcha_input" placeholder="Enter Captcha" size="large" style={{ margin: "10px 0" }} />
                            <EasySlideButton
                                onComplete={handleCaptchaVerify} // Trigger the function when slide completes
                                actionText="Slide to Verify Captcha"
                                completedText="Captcha Verified!"
                                height={50}
                                sliderColor="#FF5722"
                                backgroundColor="#f5f5f5"
                                textColor="#000"
                            />
                            <Button block size="large" onClick={() => setStep(1)} danger style={{ marginTop: 10 }}>Geri</Button>
                        </>
                    )}

                </Card>
            </IonContent>
        </IonPage>
    );
};

export default CaptchaComponent;
