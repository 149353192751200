import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from '../Conn';

interface StatComProps {
    id: string; // Define 'id' prop
}

const StatRej: React.FC<StatComProps> = ({ id }) => {
    const [rejectedCount, setRejectedCount] = useState<number | null>(null);

    useEffect(() => {
        const fetchRejectedCount = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}satisrezrvToday.php?userid=${id}`);
                console.log('Data:', response.data);
                setRejectedCount(response.data.rejectedCount);
            } catch (error) {
                console.log('Error fetching rejected count:', error);
            }
        };

        fetchRejectedCount();
    }, [id]);

    return (
        <div className='sadf comsd' style={{ background: "#717ccd" }}>
            <div className="contetnotyf">
                <div style={{ fontSize: '24px', color: '#fff' }}>{rejectedCount}</div>
                <span style={{ color: '#fff' }}>İmtina sayı</span>
            </div>
        </div>
    );
};

export default StatRej;
