import React, { useState, useEffect } from 'react';
import {
    IonContent,
    IonPage,
    IonIcon,
    IonSkeletonText,
    IonImg,
    IonButton,
    IonHeader,
    IonButtons,
    IonToolbar,
    IonRefresher,
    IonRefresherContent
} from '@ionic/react';
import { pricetags, map, eye, eyeOff } from 'ionicons/icons';
import { PicCenterOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { CONN_KEY } from '../Conn';
import CheckComp from '../components/check';
import '@ionic/react/css/ionic-swiper.css';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';
import Barcode from 'react-barcode';
import "stories-react/dist/index.css";

// import BarcodeScanner from '../components/BarcodeScanner';
interface ResetProps extends RouteComponentProps<{ id: string; }> { }

const Mainpage: React.FC<ResetProps> = ({ match }) => {
    const history = useHistory();
    const [user, setUser] = useState<any>(null);
    const [countdown, setCountdown] = useState<string>("");
    const [countdownApp, setcountdownApp] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [slideData, setSlideData] = useState<any[]>([]);
    const [notificationData, setNotificationData] = useState<any[]>([]);
    const [balanceVisible, setBalanceVisible] = useState(true);
    const [randomEmojis, setRandomEmojis] = useState('');
    const [currentHour] = useState<number>(new Date().getHours());
    const [RezervStart, setRezervStart] = useState<number>(0);
    const [RezervEnd, setRezervEnd] = useState<number>(0);
    const id = match.params.id;
    const userId = match.params.id;

    // Function to refresh data
    const refreshPage = (event: CustomEvent) => {
        setIsLoading(true);

        // Fetch the necessary data again
        fetchUserData().finally(() => {
            event.detail.complete(); // Call this to stop the refresher animation
        });
    };

    const fetchUserData = async () => {
        try {
            const userDataResponse = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
            setUser(userDataResponse.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();

        const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds
        return () => {
            clearInterval(intervalId); // Cleanup the interval when component unmounts
            setUser(null);
        };
    }, [userId, match.params.id]);

    useEffect(() => {
        if (!user || !user.endtime) {
            return;
        }

        const endTimeInMillis = new Date(user.endtime.replace(/-/g, '/')).getTime();
        if (isNaN(endTimeInMillis)) {
            return;
        }

        const updateCountdown = () => {
            const now = new Date().getTime();
            const difference = endTimeInMillis - now;

            if (difference <= 0) {
                setCountdown("Ended");

                // Check if the end notification has already been sent
                const endNotificationSent = localStorage.getItem('endNotificationSent');
                if (!endNotificationSent) {
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: "Kartınızı yenileyin",
                                body: "Kartınızın istifadə müddəti bitib",
                                id: 3,
                                schedule: { at: new Date(Date.now() + 1000) }, // Schedule immediately
                                sound: undefined,
                                attachments: undefined,
                                actionTypeId: "",
                                extra: undefined
                            }
                        ]
                    });
                    // Mark the notification as sent
                    localStorage.setItem('endNotificationSent', 'true');
                }
            } else {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                const formattedCountdown = `${days}D ${hours}H ${minutes}M ${seconds}S`;
                setCountdown(formattedCountdown);

                // Check if the one-day-left notification has already been sent
                const oneDayLeftNotificationSent = localStorage.getItem('oneDayLeftNotificationSent');
                if (days === 1 && hours === 0 && minutes === 0 && seconds === 0 && !oneDayLeftNotificationSent) {
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: "Bitmə müddəti bitir",
                                body: "Kartınızın müddətinin bitməsinə bir gün qalıb",
                                id: 1,
                                schedule: { at: new Date(Date.now() + 1000) }, // Schedule immediately
                                sound: undefined,
                                attachments: undefined,
                                actionTypeId: "",
                                extra: undefined
                            }
                        ]
                    });
                    // Mark the notification as sent
                    localStorage.setItem('oneDayLeftNotificationSent', 'true');
                }
            }
        };

        updateCountdown();
        const intervalId = setInterval(updateCountdown, 1000);

        return () => clearInterval(intervalId);
    }, [user]);

    useEffect(() => {
        if (!user || !user.App_openData) {
            return;
        }

        const endTimeInMillis = new Date(user.App_openData.replace(/-/g, '/')).getTime();
        if (isNaN(endTimeInMillis) || endTimeInMillis === 0) {
            setcountdownApp('∞');
            return;
        }

        const updateCountdownBlock = () => {
            const now = new Date().getTime();
            const difference = endTimeInMillis - now;

            if (difference <= 0) {
                setcountdownApp("Ended");
            } else {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                const formattedCountdown = `${days}D ${hours}H ${minutes}M ${seconds}S`;
                setcountdownApp(formattedCountdown);
            }
        };

        updateCountdownBlock();
        const intervalId = setInterval(updateCountdownBlock, 1000);

        return () => clearInterval(intervalId);
    }, [user]);

    useEffect(() => {
        const fetchReservationSettings = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Settings.php`);
                const data = response.data;

                if (data && data.RezervStart && data.RezervEnd) {
                    setRezervStart(data.RezervStart);
                    setRezervEnd(data.RezervEnd);
                } else {
                    console.log('Invalid response format: Missing RezervStart or RezervEnd');
                }
            } catch (error) {
                console.log('Error fetching reservation settings:', error);
            }
        };

        fetchReservationSettings();
    }, []);

    useEffect(() => {
        const fetchNotificationData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Notification.php`);
                setNotificationData(response.data);
            } catch (error) {
                console.log('Error fetching notification data:', error);
            }
        };

        fetchNotificationData();
        const intervalId = setInterval(fetchNotificationData, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Storie.php`);
                setSlideData(response.data);
            } catch (error) {
                console.log('Error fetching slide data:', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const checkUserStatus = async () => {
            const notificationSent = localStorage.getItem('accountBlockedNotificationSent');
            if (user && (user.Status === 'Blocked App' || user.Status === 'Block') && !notificationSent) {
                await LocalNotifications.schedule({
                    notifications: [
                        {
                            title: "Hesab Bloklandı",
                            body: `Səbəb: ${user.BlockSeb || user.App_reason || 'Heç bir səbəb göstərilməyib'}`,
                            id: 2,
                            schedule: { at: new Date(Date.now() + 1000) },
                            sound: undefined,
                            attachments: undefined,
                            actionTypeId: "",
                            extra: undefined
                        }
                    ]
                });
                localStorage.setItem('accountBlockedNotificationSent', 'true');
                localStorage.removeItem('accountUnblockedNotificationSent');
            }
        };

        checkUserStatus();
    }, [user]);

    useEffect(() => {
        const checkUnblockStatus = async () => {
            const notificationSent = localStorage.getItem('accountUnblockedNotificationSent');
            if (user && user.Status === null && !notificationSent) {
                await LocalNotifications.schedule({
                    notifications: [
                        {
                            title: "Hesab Aktivdir",
                            body: "Hesabınız yenidən aktivdir.",
                            id: 3,
                            schedule: { at: new Date(Date.now() + 1000) },
                            sound: undefined,
                            attachments: undefined,
                            actionTypeId: "",
                            extra: undefined
                        }
                    ]
                });
                localStorage.setItem('accountUnblockedNotificationSent', 'true');
                localStorage.removeItem('accountBlockedNotificationSent');
            }
        };

        checkUnblockStatus();
    }, [user]);

    useEffect(() => {
        if (user && isTodayUserBirthday(user.datebhrd)) {
            // Send birthday notification
            LocalNotifications.schedule({
                notifications: [
                    {
                        title: "🎉 Ad günün mübarək!",
                        body: `Ad günün mübarək, ${user.username}! 🎂🎉 Qarşıdan gələn gözəl bir il arzulayırıq!`,
                        id: 4,
                        schedule: { at: new Date(Date.now() + 1000) }, // Schedule immediately
                        sound: undefined,
                        attachments: undefined,
                        actionTypeId: "",
                        extra: undefined
                    }
                ]
            }).catch((error) => {
                console.error('Error sending birthday notification:', error);
            });
        }
    }, [user]);

    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };

    const navigateToProfile = () => {
        if (user) {
            const { id } = user;
            history.push(`/dashboard/profile/${id}`);
        } else {
            console.log("User data is not available.");
        }
    };

    const navigateToRezerv = () => {
        if (user) {
            const { id } = user;
            history.push(`/dashboard/rezervmap/${id}`);
        } else {
            console.log("User data is not available.");
        }
    };
    const navigateToSira = () => {
        if (user) {
            const { id } = user;
            history.push(`/dashboard/Sira/${id}`);
        } else {
            console.log("User data is not available.");
        }
    };
    const navigateToScan = () => {
        if (user) {
            const { id } = user;
            history.push(`/dashboard/CheckComp/${id}`);
        } else {
            console.log("User data is not available.");
        }
    };
    const navigateToSlidePage = (storieid: number) => {
        if (user) {
            const { id } = user;
            history.push(`/dashboard/Storie/userId=${id}`);
        } else {
            console.log("User data is not available.");
        }
    };

    const generateRandomEmojis = () => {
        const emojis = ['☕', '🍵', '🍶', '🎉', '🔥', '🍹', '😎', '🚀', '💪', '😄', '🥳', '🍻'];
        const randomEmojis = [];
        for (let i = 0; i < 3; i++) {
            const randomIndex = Math.floor(Math.random() * emojis.length);
            randomEmojis.push(emojis[randomIndex]);
        }
        return randomEmojis.join(' ');
    };

    const toggleBalanceVisibility = () => {
        setBalanceVisible((prevState) => !prevState);
        if (!balanceVisible) {
            setRandomEmojis(generateRandomEmojis());
        }
    };

    const isTodayUserBirthday = (datebhrd: string): boolean => {
        const today = new Date();
        const [userDay, userMonth] = datebhrd.split('.');

        return (
            parseInt(userDay, 10) === today.getDate() &&
            (parseInt(userMonth, 10) - 1) === today.getMonth()
        );
    };

    const hasEndtimePassed = user && new Date(user.endtime.replace(/-/g, '/')).getTime() < new Date().getTime();
    const [isFlipped, setIsFlipped] = useState(false);
    const [lastTap, setLastTap] = useState<number>(0);

    const handleCardClick = () => {
        const now = Date.now();
        const DOUBLE_TAP_DELAY = 300; // Time in milliseconds

        if (lastTap && (now - lastTap) < DOUBLE_TAP_DELAY) {
            // It's a double-tap
            setIsFlipped(!isFlipped);
        } else {
            // It's a single tap, update the lastTap timestamp
            setLastTap(now);
        }
    };

    return (
        // <>
        //     <IonMenu type='reveal' contentId="main-content">
        //         <IonHeader>
        //             <IonToolbar>
        //                 <IonTitle></IonTitle>
        //             </IonToolbar>
        //         </IonHeader>
        //         <IonContent className="ion-padding">
        //             <IonContent className="ion-padding">
        //                 <SettingsComp userId={userId} />
        //             </IonContent>
        //         </IonContent>
        //     </IonMenu>
        <IonPage >
            <IonHeader>
                <IonToolbar>
                    {user && (
                        <div className='imbgmain'>
                            <img src={`https://svurguns.com/Data/api/3x4/${user.filePP}`} alt='plogo' />
                        </div>
                    )}
                    {/* <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons> */}
                    <IonButtons style={{ justifyContent: 'center' }}>
                        <img src='assets/images/logo.png' style={{ width: '90px' }} alt='logo' />
                    </IonButtons>
                    <IonButtons slot="primary">
                        {user && (
                            <div className="sdffscc circular-image-container" onClick={navigateToProfile}>
                                <img src={`https://svurguns.com/Data/api/3x4/${user.filePP}`} onError={(e) => {
                                    e.currentTarget.src = "https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png";
                                    e.currentTarget.onerror = null;
                                }}
                                    alt="Profile Picture"
                                />
                            </div>
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refreshPage}>
                    <IonRefresherContent />
                </IonRefresher>
                <div className="ion-padding ion-text-center container">
                    {Array.isArray(notificationData) && notificationData.length > 0 ? (
                        <Alert
                            banner
                            message={
                                <Marquee pauseOnHover gradient={false}>

                                    <>{notificationData[0].subtitle}</>

                                </Marquee>
                            }
                        />
                    ) : null}
                    {user && (
                        <div className='imbgmain'>
                            <img src={`https://svurguns.com/Data/api/3x4/${user.filePP}`} alt='plogo' />
                        </div>
                    )}
                    <div className='container'>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                        >
                            <div className="sjter">
                                {Array.isArray(slideData) && slideData.length > 0 ? (
                                    slideData.slice(0, 1).map((slide, index) => ( // Show only the first slide by slicing the array
                                        <SwiperSlide key={index} className='boximg'>
                                            <div onClick={() => navigateToSlidePage(slide.id)}>
                                                <div className='tjvgnd'>
                                                    <IonImg src={`https://appmobile.svurguns.cyou/Data/api/app123/Storie/${slide.icon}`} alt={`Icon for ${slide.name}`} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                ) : null}
                            </div>
                        </Swiper>
                        <div className='safgd'>
                            <div className='fdgxc'>
                                <div style={{ fontSize: '16px', color: "#7f8c8d" }}>Point</div>
                                {balanceVisible ? (
                                    <div style={{ fontSize: '22px', fontWeight: 'bolder', marginTop: '10px' }}>  {user && (user.Balance)}</div>
                                ) : (
                                    <div style={{ fontSize: '20px', color: '#7f8c8d' }}>{randomEmojis}</div>
                                )}
                            </div>
                            <IonButton fill="clear" className='fcnjsd' onClick={toggleBalanceVisibility}>
                                <IonIcon icon={balanceVisible ? eye : eyeOff} aria-hidden="true" style={{ fontSize: '20px', padding: '0', color: '#7f8c8d' }} />
                            </IonButton>
                        </div>
                        <div className={`card-container ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick} >
                            <div className={`card ${isFlipped ? 'flipped' : ''} ${user && user.Status === 'ADMIN' ? ' cardAdmin' : ''}${countdown === "Ended" ? ' cardbend' : ''}${user && user.Status === 'Blocked App' ? ' cardbblock' : ''}${user && user.Status === 'Block' ? ' cardbend' : ''}`}>
                                <div className='cardjdk'>
                                    <div className="bvfdsg">
                                        {user ? (
                                            <>
                                                <div style={{ fontSize: '24px', fontWeight: '700' }}>{user.username} {user.surename} </div>
                                                <div>#{user.userid}</div>
                                            </>
                                        ) : null}
                                    </div>
                                    <p style={{ color: '#bebebe' }}>double click</p>
                                    {isLoading ? (
                                        <h4 className='dsfsz'>
                                            <span style={{ color: '#ff0000' }}>
                                                <IonSkeletonText animated style={{ width: '100%', height: '16px', display: 'inline-block' }} />
                                            </span>
                                        </h4>
                                    ) : (
                                        user && (
                                            <div className='dsfsz'>
                                                {countdown === "Ended" ? (
                                                    <span className='whhsd' style={{ fontSize: '16px', fontWeight: 'bolder' }}>Kartın müddəti bitib<span style={{ color: '#fff' }}>Kartınızı yeniləyin</span></span>
                                                ) : (
                                                    countdown === "" ? (
                                                        <IonSkeletonText animated style={{ width: '100%', height: '16px', display: 'inline-block' }} />
                                                    ) : (
                                                        <div className='fjvbn'>
                                                            <div className='whhsd'>
                                                                <div>Kartın bitmə vaxtı</div>
                                                                <span style={{ fontSize: '22px', fontWeight: 'bolder' }}>{countdown}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {user.Status !== null ? (
                                                    <div className='whhsd'><span style={{ color: '#fff' }}>Rezerv Block</span><span>{countdownApp}</span></div>
                                                ) : (
                                                    <div>Aktive</div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="flex gap-3 card-back relative bg-white p-0.5 rounded-xl">
                                    <div className=" rounded-[10px] ">
                                        <div style={{ textAlign: 'center' }}>
                                            {user && user.Cardid && (
                                                <Barcode
                                                    width={3.5}
                                                    height={100}
                                                    displayValue={false}
                                                    background='none'
                                                    value={user.Cardid}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {/* <BarcodeScanner/> */}
                                </div>
                            </div>
                        </div>
                        <div className='sffgdj'>
                            <div className={`sadf gd2 ${(user && (user.Status === 'Blocked App' || user.Status === 'Block')) || hasEndtimePassed ? ' jvfnds' : ''}`} onClick={navigateToRezerv}>
                                <IonIcon icon={map} />
                                Xəritə
                                {currentHour >= RezervStart && currentHour < RezervEnd && (
                                    <div className="circle circle2"></div>
                                )}
                            </div>
                            <div className={`sadf gd2 ${(user && (user.Status === 'Blocked App' || user.Status === 'Block')) || hasEndtimePassed ? ' jvfnds' : ''}`} onClick={navigateToSira}>
                                <IonIcon icon={pricetags} />
                                Yer tut
                                {currentHour >= RezervStart && currentHour < RezervEnd && (
                                    <div className="circle circle2"></div>
                                )}
                            </div>

                        </div>
                        {user && (
                            <>
                                {isTodayUserBirthday(user.datebhrd) && (
                                    <div className='dsfujsd '>
                                        <div className="birthday-notification">
                                            <div className="birthday-message">
                                                <h2 className="title">🎉 Happy Birthday {user.username}! 🎉</h2>
                                                <p className="subtitle">Extra Baku- doğum gününüz təbrik edir! 🥳✨ {user.username} qarşıdan gələn sevinc, uğurlar və heyrətamiz anlarla dolu unudulmaz bir il arzulayırıq!</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <div className='listheader'>
                            <h3>Əməliyyatlar</h3>
                        </div>
                        <CheckComp />
                    </div>
                </div>
            </IonContent>
        </IonPage>
        // </>
    );
};

export default Mainpage;
