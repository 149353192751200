import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Barcode from 'react-barcode';
import { useParams, useHistory } from 'react-router-dom';
import {
    IonContent,
    IonPage,
    IonBackButton,
    IonLabel,
    IonBadge,
    IonToolbar,
} from '@ionic/react';
import { CONN_KEY } from '../Conn';

interface MasaInfo {
    Masa: string;
    selectedDay: string;
    selectedFlourId: string;
    id: string;
    selectedTime: string;
    status: string;
    username: string;
    created_at: string;
    sebeb: string;
    userId: string;
}

const Ch: React.FC = () => {
    const [MasaInfo, setMasaInfo] = useState<MasaInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isPlanChanged, setIsPlanChanged] = useState(false);
    const [isCustomerRejected, setIsCustomerRejected] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [responseData, setResponseData] = useState<string>('');
    const [user, setUser] = useState<any>(null);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const api = axios.create({
                baseURL: `${CONN_KEY}`,
            });

            const MasaResponse = await api.get(`${CONN_KEY}getmazainfoid.php?id=${id}`);
            console.log('MasaInfo:', MasaResponse.data);
            setMasaInfo(MasaResponse.data);

            if (MasaResponse.data?.userId) {
                const userId = MasaResponse.data.userId;
                const userResponse = await api.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
                console.log('UserInfo:', userResponse.data);
                setUser(userResponse.data);
            }
        } catch (error) {
            console.log('Error fetching data', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleRejectionConfirmation = async () => {
        if (!MasaInfo) {
            console.log('MasaInfo is not available');
            return;
        }

        if (rejectionReason.trim() === '') {
            setShowAlert(true);
            setResponseData('İmtina səbəbini qeyd edin.');
            return;
        }

        let sebeb = rejectionReason;

        if (isPlanChanged) {
            sebeb += '\nPlan dəyişdi';
        }

        if (isCustomerRejected) {
            sebeb += '\nMüştəri imtina etdi';
        }

        const payload = {
            id: MasaInfo.id,
            sebeb: sebeb,
            manager: 'test',
            userId: MasaInfo.userId,
        };

        try {
            const response = await axios.post(`${CONN_KEY}permissionimtina.php`, payload);

            console.log(response.data);

            if (response.data.message === 'Reservation rejection successful.') {
                setResponseData('Reservation rejection successful.');
                closeModal();
            } else if (response.data.message === 'Uğursuz: Masa artıq imtina edilib') {
                setResponseData('Uğursuz: Masa artıq imtina edilib');
                closeModal();
            } else {
                setResponseData('Unknown error occurred.');
            }

            setShowAlert(true);
        } catch (error) {
            console.log('Error handling rejection:', error);
            setResponseData('An error occurred while processing the request.');
            setShowAlert(true);
        }
    };

    const formatMasa = (masa: string | undefined) => {
        if (!masa) {
            return '';
        }

        const prefix = masa.slice(0, 2);
        if (prefix === '10' || prefix === '20' || prefix === '30') {
            return masa.slice(2);
        }
        return masa;
    };

    const formatFlor = (masa: string | undefined) => {
        if (!masa) {
            return '';
        }

        if (masa === 'E1V') {
            return 'V' + masa.slice(4);
        }

        return masa;
    };

    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const gaULCookie = getCookie('_gaUL');
            if (!gaULCookie || gaULCookie !== id) {
                return;
            }
        }

        setIsLoading(false);

        const fetchUserData = () => {
            axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
                .then(res => {
                    setUser(res.data);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        fetchUserData();

        const intervalId = setInterval(fetchUserData, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [history, id]);

    return (
        <IonPage>
            <IonToolbar>
                <div className='tabbarmains'>
                    <IonBackButton defaultHref={user ? `/dashboard/${user.id}` : ""} />
                </div>
            </IonToolbar>
            <IonContent fullscreen className="ion-padding ion-text-center">
                <div className="cek-container">
                    {MasaInfo && (
                        <div className="cek-1">
                            <div className="list">
                                <div className="sjnds">
                                    <IonLabel>Ərazi - Masa - Mərtəbə</IonLabel>
                                    <span>{formatFlor(MasaInfo.selectedFlourId)} - {formatMasa(MasaInfo.Masa)}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel>Gün</IonLabel>
                                    <span>{new Date(MasaInfo.selectedDay).toLocaleDateString('en-GB')}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel>Id</IonLabel>
                                    <span>{MasaInfo.id}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel>Saat</IonLabel>
                                    <span>{MasaInfo.selectedTime}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel>Status</IonLabel>
                                    <IonBadge>
                                        {MasaInfo.status === 'ended' && new Date(MasaInfo.created_at).toLocaleDateString() !== new Date().toLocaleDateString() && (
                                            <IonLabel>Müddəti bitib</IonLabel>
                                        )}
                                        {MasaInfo.status === 'pending' && (
                                            <IonLabel>Yoxlanılır</IonLabel>
                                        )}
                                        {MasaInfo.status === 'complete' && (
                                            <IonLabel>Rezerv edilib</IonLabel>
                                        )}
                                        {MasaInfo.status === 'rejected' && (
                                            <>
                                                <IonLabel>İmtina edildi</IonLabel>
                                            </>
                                        )}
                                        {MasaInfo.status === 'cancelled' && (
                                            <>
                                                <IonLabel>İmtina edildi</IonLabel>
                                            </>
                                        )}
                                    </IonBadge>
                                </div>
                                <div className="sjnds">
                                    <IonLabel>User</IonLabel>
                                    <span>{user?.username} {user?.surename}</span>
                                </div>
                                <span>{MasaInfo.created_at}</span>
                                <IonLabel>Imtina səbəbi:{MasaInfo.sebeb}</IonLabel>
                            </div>
                            <img
                                src={`https://api.qrserver.com/v1/create-qr-code/?data=${MasaInfo.id}`}
                                alt={`QR code for Masa ${MasaInfo.Masa}`}
                                className="qrcartd"
                            />
                            <Barcode value={MasaInfo.id} height={50} />
                        </div>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Ch;
