import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from '../Conn';
import { IonBackButton, IonContent, IonPage, IonTitle, IonToolbar, IonRefresher, IonRefresherContent, IonHeader } from '@ionic/react';
import { RouteComponentProps } from 'react-router';

interface StatComProps extends RouteComponentProps<{ id: string }> { }

const StatAll: React.FC<StatComProps> = ({ match }) => {
    const userId = match.params.id;
    const [topThreeUsers, setTopThreeUsers] = useState<any[]>([]);
    const [rankedUsers, setRankedUsers] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);

    const fetchTopUsers = async (userId: string) => {
        if (!userId) {
            console.error('User ID is not defined');
            setError('User ID is not defined');
            return;
        }
        try {
            console.log(`Fetching data for user ID: ${userId}`);
            const response = await axios.get(`${CONN_KEY}StatUserRezerv.php?userId=${userId}`);
            console.log('API Response:', response);
            if (response.data) {
                const data = response.data;
                const sortedTopThree = [data.topThreeUsers[1], data.topThreeUsers[0], data.topThreeUsers[2]]; // Sorting 2, 1, 3
                setTopThreeUsers(sortedTopThree);
                setRankedUsers(data.rankedUsers || []);
                setError(null); // Clear any previous errors
            } else {
                setError('No data returned from API');
            }
        } catch (error) {
            console.error('Error fetching top users:', error);
            setError('Error fetching top users');
        }
    };

    useEffect(() => {
        console.log(`Component mounted with user ID: ${userId}`);
        if (userId) {
            fetchTopUsers(userId);
        } else {
            console.error('ID prop is not defined');
            setError('ID prop is not defined');
        }
    }, [userId]);

    const handleRefresh = (event: CustomEvent) => {
        console.log(`Refreshing data for user ID: ${userId}`);
        if (userId) {
            fetchTopUsers(userId).then(() => {
                event.detail.complete();
            });
        } else {
            console.error('ID prop is not defined for refresh');
            setError('ID prop is not defined for refresh');
            event.detail.complete();
        }
    };
    return (
        <IonPage>
            <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="tabbarmains">
            <IonBackButton defaultHref={`/dashboard/${userId}`} />
          </div>
          <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Liderlər lövhəsi</IonTitle>
        </IonToolbar>
      </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center container">
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent />
                </IonRefresher>
                <div className='container'>
                    <div className='Rectangle12201'></div>
                    <div className='RankedUsersContainer'>
                        <div className='RankedUsersContent'>
                            {error ? (
                                <p>Error: {error}</p>
                            ) : rankedUsers.length > 0 ? (
                                rankedUsers.map((user, index) => (
                                    <div key={index} className={`user-card ${user.userId.toString() === userId ? 'mesd' : ''}`}>
                                        <div>#{user.rowNumber}</div>
                                        <div className='user-info'>
                                            <p className='username'>{user.username} {user.surename}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No users found around you.</p>
                            )}
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default StatAll;
