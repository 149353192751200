import { LocalNotifications } from '@capacitor/local-notifications';
import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonNote, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import { message } from 'antd';
import axios, { AxiosResponse } from "axios";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CONN_KEY, CONN_KEY_version } from '../../Conn';
import { database } from '../../firebaseConfig'; // Import the database from your Firebase config
import EasySlideButton from './SlideButton';

const Masa: React.FC = () => {
  const renderR2Buttons = (isDisabled: boolean) => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", gap: '3px', alignItems: 'flex-end' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 47)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 50)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 53)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 56)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 4 }, (_, index) => index + 59)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const renderR1Buttons = (isDisabled: boolean) => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", gap: '3px' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 32)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 35)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 38)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 41)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 44)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const renderB1Buttons = (isDisabled: boolean) => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", gap: '3px', alignItems: 'flex-end' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 1 }, (_, index) => index + 63)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const renderB2Buttons = (isDisabled: boolean) => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", gap: '3px' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "column-reverse" }}>
            {Array.from({ length: 3 }, (_, index) => index + 67)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "column-reverse" }}>
            {Array.from({ length: 3 }, (_, index) => index + 64)
              // .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const renderL2Buttons = (isDisabled: boolean) => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", gap: '3px' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 29)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 26)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 23)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 20)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 17)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const renderL1Buttons = (isDisabled: boolean) => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", gap: '3px' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 14)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 11)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 8)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 3 }, (_, index) => index + 5)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "row" }}>
            {Array.from({ length: 4 }, (_, index) => index + 1)
              .reverse() // Reverse the array order
              .map((masaId) => {
                const isReserved = masaStatuses[masaId] === 'complete';
                const isSelected = selectedTable === masaId;
                return (
                  <button
                    key={`masa_${masaId}`}
                    style={{
                      width: '55px',
                      height: '55px',
                      borderRadius: '12px',
                      backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                    }}
                    onClick={() => handleButtonClick(masaId)}
                  >
                    {masaId}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const [selectedDay, setSelectedDay] = useState(new Date().getDay());
  const [isReserved, setIsReserved] = useState(false);
  const [confirmationHash, setConfirmationHash] = useState("");
  const [user, setUser] = useState<any>(null);
  const { id, selectedFlourId } = useParams<{ id: string; selectedFlourId: string }>();
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [userId, setUserId] = useState<string | null>(null);
  const [Masa, setMasa] = useState<number>(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedTable, setSelectedTable] = useState<number>(0);
  const [note, setNote] = useState<string>('');
  const [todayDate, setTodayDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userStatus, setUserStatus] = useState<string | null>(null);
  const [currentHour, setCurrentHour] = useState<number>(new Date().getHours());
  const [RezervStart, setRezervStart] = useState<number>(0);
  const [RezervEnd, setRezervEnd] = useState<number>(0);
  const [masaStatuses, setMasaStatuses] = useState<{ [key: number]: string }>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [randomizedBirthday, setRandomizedBirthday] = useState<(string | "_")[][]>([]);
  const [userInput, setUserInput] = useState<(string | "_")[][]>([]);
  const inputRefs = useRef<(HTMLInputElement | null)[][]>([]); // Allow nulls in the array
  const [targetBirthday, setTargetBirthday] = useState<string | null>(null);
  const [showBirthdayInput, setShowBirthdayInput] = useState(false);
  const [isBirthdayConfirmed, setIsBirthdayConfirmed] = useState(false);
  const [isKeyConfirmed, setIsKeyConfirmed] = useState(false);

  // Fetch reservation settings on component mount
  useEffect(() => {
    const fetchReservationSettings = async () => {
      try {
        const response = await axios.get(`${CONN_KEY}Settings.php`);
        const data = response.data;

        if (data && data.RezervStart) {
          setRezervStart(data.RezervStart);
          setRezervEnd(data.RezervEnd);
        } else {
          console.log('Invalid response format: Missing RezervStart');
        }
      } catch (error) {
        console.log('Error fetching reservation settings:', error);
      }
    };

    fetchReservationSettings();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1);

    return () => clearInterval(intervalId);
  }, []);

  // Fetch reserved masa IDs and listen for real-time updates
  const fetchReservedMasaIds = () => {
    setIsLoading(true);

    // Reference to the reservations in Firebase
    const masaIdsRef = ref(database, 'reservationsL2');

    // Set up a listener for real-time updates
    onValue(masaIdsRef, (snapshot: any) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const masaIds = Object.keys(data).map(id => parseInt(id, 10));  // Convert keys to numbers
        console.log("Fetched masaIds from Firebase:", masaIds);

        const statusMap = masaIds.reduce((acc: { [key: number]: string }, masaId) => {
          const reserved = data[masaId] !== null;  // Check if the masaId has any data
          acc[masaId] = reserved ? 'complete' : 'available';
          return acc;
        }, {});

        console.log("Updated masaStatuses:", statusMap);
        setMasaStatuses(statusMap);
      } else {
        console.log("No data available");
      }
      setIsLoading(false);
    }, (error: any) => {
      console.error('Error fetching masa statuses:', error);
      setIsLoading(false);
    });
  };
  // Call the function when your component is mounted
  useEffect(() => {
    fetchReservedMasaIds();
  }, []);

  const handleRefresh = (event: CustomEvent) => {
    fetchReservedMasaIds();
    if (event) {
      event.detail.complete();  // Stops the spinner
    }
  };

  const resetConfirmationSteps = (resetBirthday = true) => {
    setIsKeyConfirmed(false);
    setIsBirthdayConfirmed(false);
    setShowBirthdayInput(false);
    setConfirmationHash("");

    if (resetBirthday && birthday) {
      const randomized = generateRandomizedBirthday(birthday);
      setRandomizedBirthday(randomized);
      setUserInput(randomized.map((part) => part.map(() => "")));
    }
  };

  const handleRezervClick = async () => {
    if (!isBirthdayConfirmed) {
      message.error("Doğum tarixi təsdiqlənməyib.");
      return;
    }
  
    if (!selectedTable) {
      message.error("Masa seçilməyib.");
      return;
    }
  
    if (!confirmationHash) {
      message.error("Zəhmət olmasa yenidən cəhd edin.13");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const endpointUrl = getEndpointUrl(selectedFlourId);
      const data = {
        selectedDay: new Date().toISOString().split("T")[0],
        selectedFlourId,
        note,
        userId: id,
        Masa: selectedTable,
        confirmationKey: confirmationHash, // Include the confirmation key
      };
  
      // Retrieve API keys from environment variables or configuration
      const authKey = process.env.REACT_APP_AUTH_KEY;
  
      if (!authKey) {
        message.error("API açarları və ya AUTH açarı tapılmadı. Zəhmət olmasa sistem parametrlərini yoxlayın.");
        return;
      }
  
      // console.log("Auth Key:", process.env.REACT_APP_AUTH_KEY);
  
      // Make the Axios POST request with headers
      const response = await axios.post(endpointUrl, data, {
        headers: {
          Authorization: `Bearer ${authKey}`,
          "Content-Type": "application/json", // Ensure JSON content type
        },
      });
  
      if (response.data?.message === "Rezervasiya əlavə edildi.") {
        setShowSuccessModal(true);
        handleApiResponse(response);
      } else {
        setModalMessage(response.data?.message || "Rezervasiya əlavə etmək alınmadı.");
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during reservation:", error);
      setModalMessage("Rezervasiya zamanı xəta baş verdi.");
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getEndpointUrl = (flourId: string | undefined): string => {
    const base = `${CONN_KEY}`;
    switch (flourId) {
      case "L1": return `${base}Extra/reservL1.php`;
      case "L2": return `${base}Extra/reservL2.php`;
      case "R1": return `${base}Extra/reservR1.php`;
      case "R2": return `${base}Extra/reservR2.php`;
      default: return `${base}Extra/reservB.php`;
    }
  };
  const handleApiResponse = async (response: AxiosResponse<any>) => {
    if (response.data && response.data.message) {
      setModalMessage(response.data.message);

      // Check for successful reservation
      if (response.data.message === "Rezervasiya əlavə edildi.") {
        try {
          // Get Firebase database reference
          const db = getDatabase();
          const reservationRef = ref(db, `reservationsL2/${selectedTable}`);

          // Current date formatting
          const currentDate = new Date();
          const formattedSelectedDay = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

          // Add data to Firebase
          await set(reservationRef, {
            Masa: selectedTable,
            selectedDay: formattedSelectedDay,
            selectedTime: "23:00",
            selectedFlourId,
            note,
            userId: id,
            status: 'complete',
          });

          // Show success message
          setShowSuccessModal(true);
          resetConfirmationSteps();
          sendSuccessNotification(response.data.message);

          // Reset all states
          setMasa(0);
          setSelectedTable(0);
          setSelectedTime("");
          setNote("");

          // Reset confirmation steps and regenerate birthday
          resetConfirmationSteps();
          if (birthday) {
            const randomized = generateRandomizedBirthday(birthday);
            setRandomizedBirthday(randomized);
            setUserInput(randomized.map(part => part.map(() => "")));
          }

          // Fetch updated masa statuses
          await fetchReservedMasaIds();

        } catch (error) {
          console.error("Error updating Firebase:", error);
          message.error("Rezervasiya qeydə alındı, lakin yenilənmədə xəta baş verdi.");
        }
      }

      // Always handle repeat status after Firebase update
      if (response.data.status === "repeat") {
        message.info("Təhlükəsizlik yoxlaması yeniləndi. Zəhmət olmasa yenidən cəhd edin.");
        resetConfirmationSteps();
        if (birthday) {
          const randomized = generateRandomizedBirthday(birthday);
          setRandomizedBirthday(randomized);
          setUserInput(randomized.map(part => part.map(() => "")));
        }
      } else if (response.data.message.includes("UĞURSUZ")) {
        setShowErrorModal(true);
        resetConfirmationSteps();
      }
    } else {
      setModalMessage("Serverdən gözlənilməz cavab alındı.");
      setShowErrorModal(true);
      resetConfirmationSteps();
    }
  };

  const [birthday, setBirthday] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!id) {
          console.error("No ID provided. Cannot fetch user data.");
          return;
        }

        // Fetch user data from the API
        const response = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`);
        const data = response.data;

        if (data && data.datebhrd) {
          const birthday = data.datebhrd;

          // Set the target birthday
          setBirthday(birthday); // Store the birthday in state

          // Randomize the birthday and initialize state
          const randomized = generateRandomizedBirthday(birthday);
          setRandomizedBirthday(randomized);
          setUserInput(
            randomized.map((part) =>
              part.map((digit) => (digit === "" ? "" : digit)) // Ensure empty slots are prepared for input
            )
          );
        } else {
          console.warn("No valid birthday data found in API response.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]); // Runs only when 'id' changes

  const generateRandomKey = (length = 16): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setConfirmationHash(result); // Save the key to the state
    setIsBirthdayConfirmed(false);
    return result;
  };

  useEffect(() => {
    if (!birthday) {
      console.error("Birthday is not defined.");
      setRandomizedBirthday([[""], [""], [""]]); // Reset to empty structure
      return;
    }

    const randomized = generateRandomizedBirthday(birthday);
    setRandomizedBirthday(randomized);
    setUserInput(
      randomized.map((part) => part.map((digit) => (digit === "" ? "" : digit)))
    );
  }, [birthday]);

  const handleSlideCompleteKey = async () => {
    const randomKey = generateRandomKey();
    const userId = id;
    try {
      const response = await axios.post(`${CONN_KEY}confirmationKey.php`, {
        userid: userId,
        key: randomKey,
      });
      if (response.data.status === "success") {
        message.success("Please confirm your birthday.");
        setIsKeyConfirmed(true); // Set key confirmation
        setShowBirthdayInput(true);
      } else {
        message.error(response.data.message || "Failed to generate random key.");
      }
    } catch (error) {
      console.error("Error during key generation:", error);
      message.error("Failed to generate random key.");
    }
  };

  const handleInputChange = (value: string, partIndex: number, digitIndex: number) => {
    const updatedInput = [...userInput];
    updatedInput[partIndex][digitIndex] = value.slice(-1); // Limit to one character
    setUserInput(updatedInput);

    // Auto-focus logic: Skip already-filled inputs
    if (value && digitIndex < randomizedBirthday[partIndex].length - 1) {
      inputRefs.current[partIndex][digitIndex + 1]?.focus();
    } else if (
      value &&
      digitIndex === randomizedBirthday[partIndex].length - 1 &&
      partIndex < randomizedBirthday.length - 1
    ) {
      inputRefs.current[partIndex + 1]?.[0]?.focus();
    }
  };

  const generateRandomizedBirthday = (birthday: string): string[][] => {
    if (!birthday || !birthday.includes(".")) {
      console.error("Invalid birthday format. Expected format: DD.MM.YYYY");
      return [[""], [""], [""]];
    }

    const parts = birthday.split(".");

    if (parts.length !== 3) {
      console.error("Invalid birthday parts. Expected DD.MM.YYYY");
      return [[""], [""], [""]];
    }

    // Convert birthday into a single array of digits
    const allDigits = parts.map(part => part.split("")).flat();

    // Randomly select 5 positions to be empty
    const positions = new Set<number>();
    while (positions.size < 5) {
      positions.add(Math.floor(Math.random() * allDigits.length));
    }

    // Create the randomized structure maintaining the original format
    let currentIndex = 0;
    return parts.map(part =>
      part.split("").map(digit => {
        const shouldBeEmpty = positions.has(currentIndex++);
        return shouldBeEmpty ? "" : digit;
      })
    );
  };

  const handleSlideCompleteBirthday = () => {
    // Reconstruct the birthday from both randomized and user input
    const reconstructedBirthday = randomizedBirthday.map((part, partIndex) =>
      part.map((digit, digitIndex) =>
        digit === "" ? userInput[partIndex][digitIndex] || "" : digit
      ).join("")
    ).join(".");

    // Compare with the original birthday
    if (!birthday || reconstructedBirthday !== birthday) {
      message.error("Doğum tarixinizi düzgün daxil edin.");
      return false;
    }

    setIsBirthdayConfirmed(true);
    message.success("Doğum tarixi təsdiqləndi.");
    return true;
  };

  const renderRandomizedBirthdaySelector = () => {
    const labels = ["Gün", "Ay", "İl"];
    return (
      <div>
        <h3>Doğum tarixinizi daxil edin*</h3>
        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', gap: "10px" }}>
          {randomizedBirthday.map((part, partIndex) => (
            <div key={partIndex}>
              <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
                {labels[partIndex]}:
              </label>
              <div style={{ display: "flex", gap: "5px" }}>
                {part.map((digit, digitIndex) => (
                  <input
                    key={digitIndex}
                    ref={(el) => {
                      if (!inputRefs.current[partIndex]) {
                        inputRefs.current[partIndex] = [];
                      }
                      inputRefs.current[partIndex][digitIndex] = el!;
                    }}
                    type="number"
                    maxLength={1}
                    value={digit === "" ? userInput[partIndex][digitIndex] : digit}
                    onChange={(e) => handleInputChange(e.target.value, partIndex, digitIndex)}
                    disabled={digit !== ""}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "6px",
                      backgroundColor: digit === "" ? "#fff" : "#f0f0f0",
                      color: digit === "" ? "#000" : "#888",
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  useEffect(() => {
    console.log("Randomized Birthday Updated:", randomizedBirthday);
  }, [randomizedBirthday]);

  useEffect(() => {
    console.log("User Input Updated:", userInput);
  }, [userInput]);

  useEffect(() => {
    if (!birthday) {
      console.error("Birthday is not defined.");
      return;
    }

    if (randomizedBirthday.length === 0) { // Generate only if empty
      const randomized = generateRandomizedBirthday(birthday);
      setRandomizedBirthday(randomized);
      setUserInput(randomized.map((part) => part.map(() => ""))); // Initialize empty user input
    }
  }, [birthday]); // Only regenerate when the birthday changes

  const sendSuccessNotification = (message: string) => {
    LocalNotifications.schedule({
      notifications: [
        {
          title: "Rezervasiya təsdiqləndi",
          body: message,
          id: 1,
          schedule: { at: new Date(Date.now() + 300) }, // Schedule immediately
          sound: undefined,
          attachments: undefined,
          actionTypeId: "",
          extra: undefined
        }
      ]
    });
  };
  const handleButtonClick = (buttonId: number) => {
    if (currentHour < RezervStart || currentHour >= RezervEnd) {
      message.error("Rezervasiya saatı xaricində seçim edilə bilməz.");
      return;
    }
    setMasa(buttonId);
    setSelectedTable(buttonId);
  };
  
  const renderButtons = () => {
    if (selectedFlourId === null) {
      return <div>Loading...</div>;
    }
  
    const isDisabled = currentHour < RezervStart || currentHour >= RezervEnd;
  
    switch (selectedFlourId) {
      case 'L1':
        return renderL1Buttons(isDisabled);
      case 'L2':
        return renderL2Buttons(isDisabled);
      case 'R2':
        return renderR2Buttons(isDisabled);
      case 'B2':
        return renderB2Buttons(isDisabled);
      case 'B1':
        return renderB1Buttons(isDisabled);
      case 'R1':
        return renderR1Buttons(isDisabled);
      default:
        console.warn('Unrecognized selectedFlourId:', selectedFlourId);
        return <div>Error: Unrecognized selectedFlourId</div>;
    }
  };
  
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  useEffect(() => {
    // Define a function to fetch the version data after a delay
    const fetchVersionDataWithDelay = () => {
      // Use setTimeout to delay the fetching of data by 5 seconds (5000 milliseconds)
      setTimeout(() => {
        axios.get(CONN_KEY + 'version.php')
          .then((response) => {
            const serverVersion = response.data.version;
            if (serverVersion !== CONN_KEY_version) {
              setShowUpdateAlert(true);
            }
          })
          .catch((error) => {
            console.log('Error fetching version:', error);
          });
      }, 5000); // 5 seconds delay
    };

    // Call the function to fetch the version data
    fetchVersionDataWithDelay();
  }, []);
  const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=extra.club.baku', '_blank');
    setShowUpdateAlert(false);
  };
  return (
    <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
      <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className='tabbarmains'>
            <IonBackButton defaultHref={id ? `/dashboard/Extra/${id}` : ""} />
          </div>
          <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Reservation</IonTitle>
          <IonButtons slot="primary" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {currentHour >= RezervStart && currentHour < RezervEnd && (
              <>Aktiv  <div className="circle"></div></>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} fullscreen className="ion-padding ion-text-center" style={{ display: 'flex', justifyContent: 'center' }}>
        <IonRefresher style={{ zIndex: 999 }} slot="fixed" onIonRefresh={handleRefresh} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </IonRefresher >
        <div className='container' style={{ marginBottom: '70px' }}>
          {/* <div className='Selected-datetime'>
            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{todayDate}</IonLabel>
          </div> */}
          <h3 className='title-dayfd'>Masa *</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', justifyContent: 'center', marginBottom: '12px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '287px' }}>{renderButtons()}</div>
          </div>
          <h3 className="title-dayfd">Birthday *</h3>

          <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Qeyd</IonLabel>
          <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <textarea
              className='ion-input'
              // style={{ padding: '20px', borderRadius: '10px' }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </IonItem>
          <IonNote color="medium" class="ion-margin-horizontal" style={{ marginBottom: '18px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Qeyd! Burada rezervə aid qeydləri yaza bilərsiz
          </IonNote>
          <div>
            {!isKeyConfirmed && (
              <EasySlideButton
                onComplete={handleSlideCompleteKey}
                actionText="Rezerv etmək üçün sürüşdürün"
                completedText="Reserv başladıldı!"
                height={50}
                sliderColor="#4caf50"
                backgroundColor="#f5f5f5"
                textColor="#000"
                disabled={selectedTable === 0} // Disable if no table is selected
              />
            )}

            {isKeyConfirmed && !isBirthdayConfirmed && (
              <>
                {showBirthdayInput && renderRandomizedBirthdaySelector()}
                <br />
                <EasySlideButton
                  onComplete={handleSlideCompleteBirthday}
                  actionText="Doğum tarixinizi təsdiqləmək üçün sürüşdürün"
                  completedText="Doğum tarixi təsdiqləndinir!"
                  height={50}
                  sliderColor="#2196f3"
                  backgroundColor="#f5f5f5"
                  textColor="#000"
                  disabled={selectedTable === 0} // Disable if no table is selected
                />
              </>
            )}

            {isKeyConfirmed && isBirthdayConfirmed && (
              <EasySlideButton
                onComplete={handleRezervClick}
                actionText="Rezervi Təstiq etmək üçün sürüşdürün"
                completedText="Rezerv edildilir!"
                height={50}
                sliderColor="#ff5722"
                backgroundColor="#f5f5f5"
                textColor="#000"
                disabled={selectedTable === 0} // Disable if no table is selected
              />
            )}
          </div>

          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Reservation Message'}
            message={alertMessage}
            buttons={[
              {
                text: 'OK',
                cssClass: 'alert-button-confirm',
              },
            ]}
          />
        </div>
      </IonContent>
      <IonLoading
        isOpen={isLoading}
        onDidDismiss={() => setIsLoading(false)}
        message={'Loading...'}
        duration={5000}
        spinner="dots"
      />

      <IonModal isOpen={showUpdateAlert} onDidDismiss={() => {
        setShowUpdateAlert(false);
        resetConfirmationSteps();
      }}>
        <IonContent className="ion-padding modal-Success" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <h1 style={{ fontSize: '40px', color: 'black' }}>
            Əlçatan yeni yeniləməniz var. Xaiş olunur, yeniləməni tətbiq etmək üçün Google Play Store-a keçin.
          </h1>
          <IonButton expand="block" onClick={openPlayStore} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Yenilə
          </IonButton>
        </IonContent>
      </IonModal>
      {/* Uğurlu */}
      <IonModal isOpen={showSuccessModal} onDidDismiss={() => {
        setShowSuccessModal(false);
        resetConfirmationSteps(); // Reset birthday confirmation and other states
      }}>
        <IonContent className="ion-padding modal-Success" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IonIcon style={{ fontSize: '150px', color: 'green' }} icon={checkmarkCircleOutline} />
            <div style={{ fontSize: '38px', color: 'black' }}>{modalMessage}</div>
          </div>
          <IonButton expand="block" onClick={() => setShowSuccessModal(false)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Close</IonButton>
        </IonContent>
      </IonModal>
      {/* Uğursuz */}
      <IonModal isOpen={showErrorModal} onDidDismiss={() => { setShowErrorModal(false); resetConfirmationSteps(); }} >
        <IonContent className="ion-padding modal-Error" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IonIcon style={{ fontSize: '150px' }} icon={closeCircleOutline} />
            <div style={{ fontSize: '30px', fontWeight: 'bold', color: 'black' }}>{modalMessage}</div>
            <IonButton expand="block" onClick={() => setShowErrorModal(false)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Close</IonButton>
          </div>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Masa;
