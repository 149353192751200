import { IonContent, IonBackButton, IonPage, IonItem, IonLabel, IonList, IonSkeletonText, IonButton, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { CONN_KEY_version } from '../Conn';
import { CONN_KEY } from '../Conn';
import PrifileComponentLink from '../components/ProfileCompenentLink';
import StatRej from '../components/statism';
import StatCom from '../components/statComplate';

interface ResetProps
  extends RouteComponentProps<{
    id: string;
  }> { }

const Dashboard: React.FC<ResetProps> = ({ match }) => {
  const userId = match.params.id;
  const history = useHistory();
  const [user, setUser] = useState<any>(null);
  const id = match.params.id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data based on userId
        const userDataResponse = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
        setUser(userDataResponse.data);
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };

    fetchData(); // Fetch data when userId changes

    return () => {
      // Cleanup function
      setUser(null); // Reset user state when component unmounts
    };
  }, [userId]);

  const handleLogout = () => {
    // Delete cookies
    document.cookie = '_gaUL=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = '_ga_W5HYH32Y3F=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Check if rememberMe is false
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe === 'false') {
      // Delete items from local storage
      localStorage.removeItem('Fin');
      localStorage.removeItem('username');
      localStorage.removeItem('darkMode');
    }

    // Redirect to the login page
    history.push('/login');
  };

  return (
    <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
       <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="tabbarmains">
            <IonBackButton defaultHref="/" />
          </div>
          <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Profil</IonTitle>
        </IonToolbar>
      </IonHeader>
        <IonContent fullscreen className="ion-padding ion-text-center" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="container">
          <div>
            {user ? (
              <div className='user-main'>
                <div className="user-info">
                  <h4 className='whh'>{user.username} {user.surename}</h4>
                  <h5 className='whh'>{user.Fin}</h5>
                  <p className='user-badge'>{user.status}№-{user.userid}</p>
                </div>
                {user.filePP && (
                  <img alt='pp' className='pp'
                    onError={(e) => {
                      e.currentTarget.src = "https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png";
                      e.currentTarget.onerror = null;
                    }}
                    src={`https://svurguns.com/Data/api/3x4/${user.filePP}`} />
                )}
              </div>
            ) : (
              <IonSkeletonText animated style={{ width: '100%', height: '100px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            )}
          </div>
          <br />
          <div className='sffgdj'>
            <StatRej id={id} />
            <StatCom id={id} />
          </div>
          <PrifileComponentLink userId={userId} />
          <IonList inset={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonButton fill="outline" style={{ width: '90%' }} shape="round" onClick={handleLogout} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Hesabdan çıxış</IonButton>
            <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel className='user-p version' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <h6>Applications version {CONN_KEY_version}</h6>
                <small>By Marketing Team</small>
              </IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
