import {
  IonContent, IonPage, IonRow, IonCol, IonItem, IonInput, IonButton, IonAlert, IonLabel, IonLoading,
  IonNote,
  IonCheckbox,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CONN_KEY } from '../Conn';
import { CONN_KEY_version } from '../Conn';
import { Device } from '@capacitor/device';
import type { DeviceId } from '@capacitor/device';
import { ref, onValue, set, get, onDisconnect } from "firebase/database"; // Include `remove` for logout
import { database } from "../firebaseConfig";
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';
interface SessionData {
  deviceId: string;
  isOnline: boolean;
  phoneNumber: string;
  lastLogin: number;
}

function Login() {
  const history = useHistory();
  const [Phone, setPhone] = useState<string>("");
  const [Pass, setPass] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [Otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState<boolean>(false);

  if (Capacitor.isNativePlatform()) {
    import('@capacitor/device').then((module) => {
      const Device = module.Device;
      Device.getId();
    });
  }

  useEffect(() => {
    const validateSession = async () => {
      const deviceInfo = await Device.getId();
      const { identifier: deviceId } = deviceInfo;

      const userId = localStorage.getItem("userId"); // Retrieve user ID from local storage
      if (userId) {
        const userRef = ref(database, `sessions/${userId}`);

        // Listen to changes in the user's session in Firebase
        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            const sessionData = snapshot.val();
            if (sessionData.deviceId !== deviceId) {
              // Session is invalid for the current device
              // alert("Your account has been logged out due to login on another device.");
              handleAutomaticLogout(); // Call the automatic logout function
            }
          } else {
            // Session does not exist (e.g., deleted by the server)
            alert("Your session has expired. Please log in again.");
            handleAutomaticLogout(); // Call the automatic logout function
          }
        });
      } else {
        // No user is logged in; redirect to the login page
        history.push("/login");
      }
    };

    validateSession();
  }, [history]);

  useEffect(() => {
    const initializeSession = async () => {
      const sessionValid = await validateSession();
      if (!sessionValid) {
        console.log("Session is invalid. User needs to log in.");
      }
    };

    initializeSession();

    const userId = localStorage.getItem("userId");
    if (userId) {
      const userRef = ref(database, `sessions/${userId}/isOnline`);

      // Mark the user as online when the app is active
      set(userRef, true);

      // Automatically mark user as offline on disconnection
      onDisconnect(userRef).set(false);
    }

    // Cleanup: mark user as offline on app close
    return () => {
      if (userId) {
        const userRef = ref(database, `sessions/${userId}/isOnline`);
        set(userRef, false);
      }
    };
  }, []);

  const handleAutomaticLogout = async () => {
    const userId = localStorage.getItem("userId");
    const deviceInfo = await Device.getId();
    const { identifier: deviceId } = deviceInfo;

    if (userId) {
      try {
        // Mark the current device as offline
        const sessionRef = ref(database, `sessions/${userId}/${deviceId}`);
        await set(sessionRef, { isOnline: false });

        // Clear local data and redirect to login
        localStorage.clear();
        console.log("User logged out successfully.");
        history.push("/login");
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }
  };

  const validateSession = async (): Promise<boolean> => {
    const userId = localStorage.getItem("userId");
    const deviceInfo = await Device.getId();
    const { identifier: deviceId } = deviceInfo;

    if (userId) {
      const userRef = ref(database, `sessions/${userId}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const sessions = snapshot.val();

        // Check if the current device is active
        const currentSession = sessions[deviceId];

        if (currentSession && currentSession.isOnline) {
          console.log("User is logged in and active on this device.");
          return true; // Valid session exists
        }

        // Another device is online
        const activeSession = Object.values(sessions).find((session: any) => session.isOnline);

        if (activeSession) {
          console.log("Another device is active. Logging out this device.");
          await set(ref(database, `sessions/${userId}/${deviceId}/isOnline`), false); // Mark current device as offline
          localStorage.clear();
          history.push("/login");
          return false;
        }
      }

      console.log("No valid session found. Redirecting to login...");
      history.push("/login");
      return false;
    }

    console.log("No user ID found in localStorage. Redirecting to login...");
    history.push("/login");
    return false;
  };

  const handleLogin = async () => {
    setIsLoading(true);

    let deviceInfo: DeviceId;
    try {
      deviceInfo = await Promise.race([
        Device.getId(),
        new Promise<never>((_, reject) => setTimeout(() => reject(new Error("Device.getId() timeout")), 5000))
      ]);
    } catch (error) {
      console.error("Error retrieving device ID:", error);
      setMessage("Cihaz ID-si əldə edilə bilmədi. Zəhmət olmasa yenidən yoxlayın.");
      setIsError(true);
      setIsLoading(false);
      return;
    } const { identifier: deviceId } = deviceInfo;

    const loginData = {
      deviceId,
      Pass,
      Phone,
    };

    if (rememberMe) {
      localStorage.setItem("rememberedPhone", Phone);
      localStorage.setItem("rememberedPass", Pass);
    } else {
      localStorage.removeItem("rememberedPhone");
      localStorage.removeItem("rememberedPass");
    }

    axios.post(`${CONN_KEY}login.php`, loginData, { timeout: 10000 })
      .then(async (res) => {
        const { status, id, message } = res.data;

        if (status === 1) {
          const userRef = ref(database, `sessions/${id}`);
          const snapshot = await get(userRef);

          if (snapshot.exists()) {
            const sessions = snapshot.val() as Record<string, SessionData>;

            // Check if there is any device online
            const activeSession = Object.values(sessions).find((session) => session.isOnline);

            if (activeSession) {
              if (activeSession.deviceId === deviceId) {
                // Current device is already online, allow login
                console.log("This device is already logged in.");
              } else {
                // Another device is online, block login
                setMessage("Siz artıq başqa cihazda daxil olmusunuz. Zəhmət olmasa əvvəlcə oradan çıxın.");
                setIsError(true);
                setIsLoading(false);
                return;
              }
            }
          }

          // Save session for this device and mark as online
          await set(ref(database, `sessions/${id}/${deviceId}`), {
            deviceId,
            phoneNumber: Phone,
            lastLogin: Date.now(),
            isOnline: true,
          });

          // Automatically mark this device as offline on disconnection
          const disconnectRef = ref(database, `sessions/${id}/${deviceId}/isOnline`);
          onDisconnect(disconnectRef).set(false);

          // Save user ID locally and redirect to dashboard
          localStorage.setItem("userId", id);
          // await requestNotificationPermission();
          history.push(`/dashboard/${id}`);
        } else {
          setMessage(message);
          setIsError(true);
        }
      })
      .catch(() => {
        setMessage("Network error. Please try again.");
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formatPhoneNumber = (phoneValue: string) => {
    // Remove all non-digit characters from the input
    const numericValue = phoneValue.replace(/\D/g, '');

    // Format the phone number only if it has at least 7 digits
    if (numericValue.length <= 10) {
      // Format the phone number as "000 000 00 00"
      const formattedValue = numericValue.replace(
        /(\d{3})(\d{0,3})(\d{0,2})(\d{0,2})/,
        (_, p1, p2, p3, p4) => [p1, p2, p3, p4].filter(Boolean).join(' ')
      );

      // Update the state with the formatted phone number
      setPhone(formattedValue);
    }
  };

  useEffect(() => {
    const savedPhone = localStorage.getItem("rememberedPhone");
    const savedPass = localStorage.getItem("rememberedPass");

    if (savedPhone) setPhone(savedPhone);
    if (savedPass) setPass(savedPass);

    setRememberMe(!!(savedPhone && savedPass)); // Set "Remember Me" checkbox state
  }, []);


  const handlePhoneChange = (e: CustomEvent<any>) => {
    const phoneValue = (e.target as HTMLInputElement).value;
    formatPhoneNumber(phoneValue);
  };

  const handleSendOtp = async () => {
    if (!Phone) {
      setMessage('Zəhmət olmasa telefon nömrəsi daxil edin. Və bu nömrədən qeydiyyatdan keçməyinizə əmin olun');
      setIsError(true);
      return;
    }

    const resetCode = Math.floor(100000 + Math.random() * 900000); // Generate 6-digit OTP

    try {
      // Save OTP to Firebase
      await set(ref(database, `otp/${Phone}`), {
        resetCode,
        timestamp: Date.now()
      });

      // Send OTP via API (replace with your API endpoint)
      await axios.post(`${CONN_KEY}send_otp.php`, {
        phoneNumber: Phone,
        resetCode
      });

      setMessage('OTP sent successfully.');
      setIsError(false);
      setIsOtpModalOpen(true); // Open the OTP modal
    } catch (error) {
      setMessage('Failed to send OTP. Please try again.');
      setIsError(true);
    }
  };
  const requestNotificationPermission = async () => {
    const permission = await LocalNotifications.requestPermissions();

    if (permission.display === 'granted') {
      console.log("Notification permission granted");
    } else {
      console.error("Notification permission not granted");
      throw new Error("Notifications are not enabled on this device");
    }
  };

  // Verify OTP
  const handleVerifyOtp = async () => {
    const enteredOtp = Otp.join(''); // Combine OTP array into a single string
    console.log('Entered OTP:', enteredOtp);

    try {
      const otpRef = ref(database, `otp/${Phone}`);
      const snapshot = await get(otpRef);

      if (snapshot.exists()) {
        const { resetCode } = snapshot.val();
        if (String(resetCode) === enteredOtp) {
          setMessage('OTP verified successfully.');
          setIsError(false);
          setIsOtpModalOpen(false);
          history.push(`/passreset/${Phone}`);
        } else {
          setMessage('Incorrect OTP. Please try again.');
          setIsError(true);
        }
      } else {
        setMessage('OTP not found. Please request a new one.');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error verifying OTP. Please try again.');
      setIsError(true);
    }
  };
  const [countdown, setCountdown] = useState<number>(60); // 60 seconds countdown
  const [canResend, setCanResend] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setCanResend(true); // Enable resend button when timer ends
    }
    return () => clearTimeout(timer);
  }, [countdown]);
  const otpRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const newOtp = [...Otp];

    // Allow only single digit and update OTP array
    if (value.length <= 1) {
      newOtp[index] = value;
      setOtp(newOtp);
    }

    // Move to the next input if a digit is entered
    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Backspace" && !Otp[index] && index > 0) {
      // Move to the previous input when backspacing on an empty field
      otpRefs.current[index - 1]?.focus();
    }
  };

  return (
    <IonPage>
      <IonContent className="ion-padding p-6 ">
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          cssClass="my-custom-class"
          header={"Oops"}
          message={message}
          buttons={["ok"]}
        />
        <h1 style={{ fontWeight: 'bolder', fontFamily: 'monospace', }}>Xoş gəlmisiniz</h1>
        <small style={{ color: '#aba9a9' }}>Zəhmət olmasa məlumatlarınızı daxil edin</small>
        <div className="loginformgds">
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Telefon</IonLabel>
                <IonInput
                  type="tel"
                  value={Phone}
                  placeholder='055 555 55 55'
                  maxlength={13}
                  onIonChange={handlePhoneChange}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Parol</IonLabel>
                <IonInput
                  placeholder='******'
                  type={showPassword ? 'text' : 'password'}
                  value={Pass}
                  onIonChange={(e) => setPass(e.detail.value!)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <IonLabel>Yadda saxla</IonLabel>
                <IonCheckbox
                  checked={rememberMe}
                  onIonChange={(e) => setRememberMe(e.detail.checked)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonNote className="ion-margin-horizontal">
            <span style={{ marginBottom: '18px', fontWeight: 'bold' }} onClick={handleSendOtp}>
              Parolu unutmusuz ?
            </span>
          </IonNote>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={handleLogin}>
                Giriş et
              </IonButton>
            </IonCol>
          </IonRow>
          <br />
          <IonNote className="ion-margin-horizontal">
            Hesabınız yoxdur? <span style={{ marginBottom: '18px', fontWeight: 'bold' }}>
              <Link to={'/Register'}> Qeydiyyatdan keç</Link>
            </span>
          </IonNote>
        </div>
        <br />
        <IonNote style={{ fontSize: "small" }}>
          GİRİŞ düyməsini klikləməklə Siyasətimizlə razılaşırsınız
          <br />
          <br />
          Version : {CONN_KEY_version}
        </IonNote>
        <IonLoading
          isOpen={isLoading}
          onDidDismiss={() => setIsLoading(false)}
          message={'Loading...'}
          spinner="dots"
        />
        <IonModal isOpen={isOtpModalOpen} onDidDismiss={() => setIsOtpModalOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Verify OTP</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              {Otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (otpRefs.current[index] = el)} // Attach ref to each input
                  value={digit}
                  maxLength={1} // Limit input to one digit
                  onChange={(e) => handleOtpChange(e, index)} // Handle value change
                  onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace navigation
                  style={{
                    width: '40px',
                    height: '40px',
                    textAlign: 'center',
                    fontSize: '24px',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    background: 'var(--ion-toolbar-background)'
                  }}
                />
              ))}
            </div>
            <IonButton expand="block" className="ion-margin-top" onClick={handleVerifyOtp}>
              Verify OTP
            </IonButton>
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              {countdown > 0
                ? `You can resend OTP in ${countdown} seconds.`
                : (
                  <IonButton fill="clear" onClick={handleSendOtp} disabled={!canResend}>
                    Resend OTP
                  </IonButton>
                )}
            </p>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}

export default Login;
