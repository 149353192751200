import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuEDmeiUTLiQRQBNVLKmjz85CnbSUgz3Q",
  authDomain: "senatetest-1yh5o6.firebaseapp.com",
  databaseURL: "https://senatetest-1yh5o6-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "senatetest-1yh5o6",
  storageBucket: "senatetest-1yh5o6.appspot.com",
  messagingSenderId: "106697676461",
  appId: "1:106697676461:web:d34e725410b0f998225824"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
const analytics = getAnalytics(app);
const database = getDatabase(app);

console.log(process.env.REACT_APP_API_KEY);

// Render App
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorker.unregister();
