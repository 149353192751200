import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonRouterLink,
    IonChip,
    IonLabel,
    IonList,
    IonItemOptions,
    IonItemOption,
    IonSkeletonText,
    IonItemSliding,
    IonItem,
    IonToast,
    IonContent
} from '@ionic/react';
import { caretDown, caretUp } from 'ionicons/icons';
import axios from 'axios';
import { CONN_KEY } from '../Conn';
import NotFound from './notfound';

const Drivers: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [reservations, setReservations] = useState<any[]>([]); // Define the state to hold reservations data
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        fetchData(); // Fetch data when the component mounts
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${CONN_KEY}Drivers.php?id=${id}`); // Replace 'URL_TO_YOUR_API_ENDPOINT_HERE' with your actual API URL
            if (response.data && response.data.reservations) {
                setReservations(response.data.reservations); // Update reservations state with the fetched data
            } else {
                 console.log('Data format error: reservations data not found in API response');
            }
            setLoading(false); // Set loading to false once data is fetched
        } catch (error) {
             console.log('Error fetching data:', error);
            setLoading(false); // Set loading to false in case of error
        }
    };

    const handleRefresh = async (event: CustomEvent) => {
        fetchData(); // Refetch data when the refresh event is triggered
        setTimeout(() => {
            event.detail.complete();
        }, 200);
    };

    return (
        <div>
            <IonRefresher style={{ zIndex: 999 }} slot="fixed" onIonRefresh={handleRefresh} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonRefresherContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonRefresher >
            {
                loading ? (
                    <IonList onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonSkeletonText animated={true} style={{ width: '10%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <IonSkeletonText animated={true} style={{ width: '90%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <IonSkeletonText animated={true} style={{ width: '80%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </IonLabel>
                    </IonList >
                ) : (
                    <div className="listrezv" style={{ marginTop: '18px' }}>
                        {/* Map through reservations and render them */}
                        {reservations && reservations.length > 0 ? (
                            reservations.map((reservation) => (
                                <IonRouterLink
                                    key={reservation.id}
                                    routerLink={`/dashboard/PersDriver/${reservation.id}`}
                                    routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    <div className="dsfujnh">
                                        <div className="jvfnd">
                                            <div className="head-list">
                                                <span style={{ color: '#CBFBE7' }}>#{reservation.userid}</span>
                                            </div>
                                            {reservation.username} {reservation.surename}
                                            <div className="time-list">
                                                {reservation.vezife === 'Driver' && (
                                                    <div style={{ fontSize: '16px' }} className="complete-chip"  >
                                                        {reservation.vezife}
                                                    </div>
                                                )}

                                                {reservation.vezife === 'Tour Guide' && (
                                                    <div style={{ color: '#ff0000', fontSize: '16px' }} className="complete-chip"  >
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>İşləmir</IonLabel>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </IonRouterLink>
                            ))
                        ) : (
                            <div>Sürücü tapılmadı.</div>
                        )}
                    </div>
                )
            }
        </div >
    );
};
// /PersDriver
export default Drivers;
