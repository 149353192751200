import React, { useEffect, useState, useRef } from 'react';
import { IonContent, IonPage, IonBackButton, IonHeader, IonToolbar, IonTitle, IonIcon, IonModal, IonButtons, IonButton } from '@ionic/react';
import { IonImg } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { notifications } from 'ionicons/icons';
import { CONN_KEY } from '../Conn'


const Notify: React.FC = () => {
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const [user, setUser] = useState<any>(null);
  const { id } = useParams<{ id: string }>(); // Retrieve selectedFlourId from URL path
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  };
  useEffect(() => {
    // Check if the code is running in a browser environment
    if (typeof window !== 'undefined') {
      const gaULCookie = getCookie('_gaUL');
      if (!gaULCookie || gaULCookie !== id) {
        // Don't perform redirection if _gaUL cookie doesn't exist or doesn't match id
        return;
      }
    }

    setIsLoading(false);

    const fetchUserData = () => {
      axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
        .then(res => {
          setUser(res.data);
          // If you have a calculateCountdown function, call it here
          // calculateCountdown(res.data.endtime);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchUserData(); // Fetch data initially

    const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds

    return () => {
      clearInterval(intervalId); // Cleanup the interval when component unmounts
    };
  }, [history, id]);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        const response = await axios.get(`${CONN_KEY}NotificationAll.php`);
        console.log('Notification Data:', response.data);
        setNotificationData(response.data);
      } catch (error) {
        console.log('Error fetching notification data:', error);
      }
    };

    fetchNotificationData();
  }, []);

  const openModal = () => {
    if (notificationData && notificationData.length > 0) {
      setIsOpen(true);
    }
  };

  return (
    <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
      <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="tabbarmains">
            <IonBackButton defaultHref="/" />
          </div>
          <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Notification</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} fullscreen className="ion-padding ion-text-center">
        <div className='container'>
          {notificationData.map((notification, index) => (
            <div
              key={index}
              className={notificationData && notificationData.length > 0 ? 'dsfujsd' : 'hidden'}
              onClick={openModal}
            >
              <div className="jvfnds">
                <IonIcon icon={notifications} style={{ color: '#fff' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
              <div className="contetnotyf">
                <div className="TitleNotf">{notification.Title}</div>
                <div className="SubNotf">{notification.subtitle}</div>
              </div>
            </div>
          ))}
          <IonModal isOpen={isOpen} ref={modal}>
            <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
              <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Notification</IonTitle>
                <IonButtons slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonButton onClick={() => setIsOpen(false)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    OK
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} className="ion-padding">
              <div className='dsfujsd'><h4>{notificationData && notificationData.length > 0 ? notificationData[0].paragraf : ''}</h4></div>
            </IonContent>
          </IonModal>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notify;
