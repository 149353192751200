import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // Realtime Database
import { getFirestore } from "firebase/firestore"; // Firestore

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuEDmeiUTLiQRQBNVLKmjz85CnbSUgz3Q",
  authDomain: "senatetest-1yh5o6.firebaseapp.com",
  databaseURL: "https://senatetest-1yh5o6-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "senatetest-1yh5o6",
  storageBucket: "senatetest-1yh5o6.firebasestorage.app",
  messagingSenderId: "106697676461",
  appId: "1:106697676461:web:d34e725410b0f998225824"
};

// Initialize Firebase app (only if not already initialized)
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Realtime Database
const database = getDatabase(app);

// Initialize Firestore
const firestore = getFirestore(app); // Initialize Firestore

// Export Firebase services
export { app, database, firestore, firebaseConfig };
