import React, { useState } from 'react';
import axios from "axios";
import { IonBackButton, IonButtons, IonPage, useIonViewDidEnter, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel } from '@ionic/react';
import { CONN_KEY } from '../Conn';
import { RouteComponentProps } from "react-router-dom";
import CheckComp1 from './check';

interface ResetProps extends RouteComponentProps<{ id: string }> { }

const PersonInDriver: React.FC<ResetProps> = ({ match }) => {
    const [user, setUser] = useState<any>(null);
    const [countdown, setCountdown] = useState<string>("");

    useIonViewDidEnter(() => {
        const userId = match.params.id;

        axios.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`)
            .then(res => {
                setUser(res.data);
                calculateCountdown(res.data.endtime);
            })
            .catch(() => {
                console.log("Error fetching user data");
            });
    });

    const calculateCountdown = (endTime: string) => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime();
            const endTimeInMillis = new Date(endTime).getTime();
            const difference = endTimeInMillis - now;

            if (difference <= 0) {
                clearInterval(intervalId);
                setCountdown("Ended");
            } else {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);
                const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
                setCountdown(formattedCountdown);
            }
        }, 1000);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Sürücünün məlumatları</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center">
                {user && (
                    <>
                        <IonList inset={true}>
                            <IonLabel className='user-p'>
                                <img alt='pp' className='pp'
                                    onError={(e) => {
                                        e.currentTarget.src = "https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png";
                                        e.currentTarget.onerror = null;
                                    }}
                                    src={`https://svurguns.com/Data/api/3x4/${user.filePP}`}
                                />
                            </IonLabel>
                            <IonItem className='list'>
                                <IonLabel className='user-p'>Adı:</IonLabel>
                                <IonLabel className='user-p'>{user.username}</IonLabel>
                            </IonItem>
                            <IonItem className='list'>
                                <IonLabel className='user-p'>Ata adı:</IonLabel>
                                <IonLabel className='user-p'>{user.dadname}</IonLabel>
                            </IonItem>
                            <IonItem className='list'>
                                <IonLabel className='user-p'>Soy Adı:</IonLabel>
                                <IonLabel className='user-p'>{user.surename}</IonLabel>
                            </IonItem>
                            <IonItem className='list'>
                                <IonLabel className='user-p'>Fin:</IonLabel>
                                <IonLabel className='user-p'>{user.Fin}</IonLabel>
                            </IonItem>
                            <IonItem className='list'>
                                <IonLabel className='user-p'>Telefon:</IonLabel>
                                <IonLabel className='user-p'>{user.Phone}</IonLabel>
                            </IonItem>
                            <IonItem className='list'>
                                <IonLabel className='user-p'>Reyting:</IonLabel>
                                <IonLabel className='user-p'>{user.Reyting}</IonLabel>
                            </IonItem>
                            {user.vezife !== 'Tour Guide' && (
                                <IonItem className='list'>
                                    <IonLabel className='user-p'>Kartın Bitmə tarixi:</IonLabel>
                                    <IonLabel className='user-p'>{countdown}</IonLabel>
                                </IonItem>
                            )}
                        </IonList>
                        <h3>Sürücünün rezervasiyası</h3>
                        <CheckComp1 />
                    </>
                )}
            </IonContent>
        </IonPage>
    );
}

export default PersonInDriver;
